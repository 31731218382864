var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-inner-table-container width-100"},[(_vm.selectedType === 'Customers')?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"name","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[_c('Avatar',{attrs:{"name":item.customer_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.customer_name ? item.customer_name : "-")+" ")])],1)]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.mobile_number ? item.mobile_number : "-")+" ")])]}},{key:"item.expected_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.expected_delivery_date ? _vm.formatDate(item.expected_delivery_date) : "-")+" ")])]}},{key:"item.delivered_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.assignmentdetails && item.assignmentdetails.delivered_at ? _vm.formatDate(item.assignmentdetails.delivered_at) : "-")+" ")])]}},{key:"item.billing_address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.billingaddressdetails.block_number ? item.billingaddressdetails.block_number + "," : "")+_vm._s(item.billingaddressdetails.floor_number ? item.billingaddressdetails.floor_number + "," : "-")+" "+_vm._s(item.billingaddressdetails ? item.billingaddressdetails.address : "-")+" ")]),(item.billingaddressdetails)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.billingaddressdetails.cityortown ? item.billingaddressdetails.cityortown + ", " : "")+_vm._s(item.billingaddressdetails.country ? item.billingaddressdetails.country : "")+" ")]):_vm._e()]}},{key:"item.delivery_address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.customerdetails.block_number ? item.customerdetails.block_number + "," : "")+_vm._s(item.customerdetails.floor_number ? item.customerdetails.floor_number + "," : "-")+" "+_vm._s(item.customerdetails.address ? item.customerdetails.address : "-")+" ")]),(item.customerdetails.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.customerdetails.cityortown ? item.customerdetails.cityortown + ", " : "")+_vm._s(item.customerdetails.country ? item.customerdetails.country : "")+" ")]):_vm._e()]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.customerdetails.email_address ? item.customerdetails.email_address : "-")+" ")])]}},{key:"item.delivered_img",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[(
            item.assignmentdetails && item.assignmentdetails.delivered_image
          )?_c('img',{directives:[{name:"viewer",rawName:"v-viewer",value:({
            button: true,
            navbar: false,
            title: false,
            toolbar: false,
            tooltip: false,
            movable: false,
            zoomable: false,
            rotatable: false,
            scalable: false,
            transition: true,
            fullscreen: false,
            keyboard: false,
          }),expression:"{\n            button: true,\n            navbar: false,\n            title: false,\n            toolbar: false,\n            tooltip: false,\n            movable: false,\n            zoomable: false,\n            rotatable: false,\n            scalable: false,\n            transition: true,\n            fullscreen: false,\n            keyboard: false,\n          }"}],staticClass:"pod-image cursor-pointer",attrs:{"src":(_vm.serverUrl + "/" + (item.assignmentdetails.delivered_image)),"alt":"pod-img"}}):_c('span',[_vm._v("-")])])]}}],null,false,1647634025)}):_vm._e(),(_vm.selectedType === 'Orders' && _vm.item.logs)?_c('div',{staticClass:"inner-timeline-container width-100 display-flex pl-14 pr-8 pt-6 pb-8"},[_c('div',{staticClass:"timeline-item",class:{
        active: _vm.item.logs.items['Order placed'],
      }},[_vm._m(0),_c('div',{staticClass:"details mt-4"},[_c('h1',[_vm._v("Order Placed")]),_c('h2',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.item.logs.items["Order placed"] ? _vm.formatTimelineDate(_vm.item.logs.items["Order placed"].created_at) : "")+" ")])])]),_c('div',{staticClass:"timeline-item",class:{
        active: _vm.item.logs.items['Parcel ready for shipping'],
      }},[_vm._m(1),_c('div',{staticClass:"details mt-4"},[_c('h1',[_vm._v("Parcel Ready For Shipping")]),_c('h2',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.item.logs.items["Parcel ready for shipping"] ? _vm.formatTimelineDate( _vm.item.logs.items["Parcel ready for shipping"].created_at ) : "")+" ")])])]),(_vm.item.logs.items['Parcel picked by logistics'])?_c('div',{staticClass:"timeline-item",class:{
        active: _vm.item.logs.items['Parcel picked by logistics'],
      }},[_vm._m(2),_c('div',{staticClass:"details mt-4"},[_c('h1',[_vm._v("Parcel Picked by Logistics")]),(_vm.item.logs.items['Parcel picked by logistics'])?_c('h2',{staticClass:"mt-2"},[_vm._v(" Driver Name: "+_vm._s(_vm.item.logs.items["Parcel picked by logistics"] ? _vm.item.logs.items["Parcel picked by logistics"].content : "")+" ")]):_vm._e(),_c('h2',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.item.logs.items["Parcel picked by logistics"] ? _vm.formatTimelineDate( _vm.item.logs.items["Parcel picked by logistics"].created_at ) : "")+" ")])])]):_vm._e(),(_vm.item.logs.items['Parcel picked by logistics'])?_c('div',{staticClass:"timeline-item",class:{
        active: _vm.item.logs.items['Parcel at sorting center'],
      }},[_vm._m(3),_c('div',{staticClass:"details mt-4"},[_c('h1',[_vm._v("Parcel at Sorting Center")]),_c('h2',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.item.logs.items["Parcel at sorting center"] ? _vm.formatTimelineDate( _vm.item.logs.items["Parcel at sorting center"].created_at ) : "")+" ")])])]):_vm._e(),(_vm.item.logs.items['Parcel picked by logistics'])?_c('div',{staticClass:"timeline-item",class:{
        active: _vm.item.logs.items['Parcel picked by logistics to Customer'],
      }},[_vm._m(4),_c('div',{staticClass:"details mt-4"},[_c('h1',[_vm._v("Order Out to Delivery")]),(_vm.item.logs.items['Parcel picked by logistics to Customer'])?_c('h2',{staticClass:"mt-2"},[_vm._v(" Driver Name: "+_vm._s(_vm.item.logs.items["Parcel picked by logistics to Customer"] ? _vm.item.logs.items["Parcel picked by logistics to Customer"] .content : "")+" ")]):_vm._e(),_c('h2',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.item.logs.items["Parcel picked by logistics to Customer"] ? _vm.formatTimelineDate( _vm.item.logs.items["Parcel picked by logistics to Customer"] .created_at ) : "")+" ")])])]):_vm._e(),(!_vm.item.logs.items['Parcel picked by logistics'])?_c('div',{staticClass:"timeline-item",class:{
        active: _vm.item.logs.items['Parcel picked by logistics to Customer'],
      }},[_vm._m(5),_c('div',{staticClass:"details mt-4"},[_c('h1',[_vm._v("Parcel picked by logistics to Customer")]),(_vm.item.logs.items['Parcel picked by logistics to Customer'])?_c('h2',{staticClass:"mt-2"},[_vm._v(" Driver Name: "+_vm._s(_vm.item.logs.items["Parcel picked by logistics to Customer"] ? _vm.item.logs.items["Parcel picked by logistics to Customer"] .content : "-")+" ")]):_vm._e(),_c('h2',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.item.logs.items["Parcel picked by logistics to Customer"] ? _vm.formatTimelineDate( _vm.item.logs.items["Parcel picked by logistics to Customer"] .created_at ) : "")+" ")])])]):_vm._e(),_c('div',{staticClass:"timeline-item",class:{
        active: _vm.item.logs.items['Order delivered'],
      }},[_vm._m(6),_c('div',{staticClass:"details mt-4"},[_c('h1',[_vm._v("Order Delivered")]),_c('h2',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.item.logs.items["Order delivered"] ? _vm.formatTimelineDate( _vm.item.logs.items["Order delivered"].created_at ) : "")+" ")])])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"marker"}),_c('div',{staticClass:"progress-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"marker"}),_c('div',{staticClass:"progress-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"marker"}),_c('div',{staticClass:"progress-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"marker"}),_c('div',{staticClass:"progress-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"marker"}),_c('div',{staticClass:"progress-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"marker"}),_c('div',{staticClass:"progress-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"marker"})])}]

export { render, staticRenderFns }