<template>
  <div>
    <CommonToolbar
      title="Products"
      :primaryButtonLabel="
        loggedUserPermission.edit_products &&
          loggedUserPermission.view_products &&
          'Add Product'
      "
      :secondaryButtonLabel="loggedUserPermission.view_products && 'Export'"
      :isSearch="loggedUserPermission.view_products"
      @search="onSearch"
      @primaryButtonClick="addClick"
      @secondaryButtonClick="exportClick"
    >
      <!-- Filter Slot -->
      <v-edit-dialog ref="productFilterModal">
        <v-btn
          v-if="loggedUserPermission.view_orders"
          class="mr-4 position-relative"
          icon
          dark
        >
          <img src="@/assets/icons/filter.svg" />
          <div v-if="isProductFilter" class="filter-marker position-absolute"></div>
        </v-btn>
        <template v-slot:input>
          <ProductFilter
            @onClose="resetFilterClicked"
            @onFilter="filterClicked"
            :key="filterModalKey"
          />
        </template>
      </v-edit-dialog>
    </CommonToolbar>
    <SubHeader :items="items" />
    <div class="product-table-container">
      <TableSection v-if="loggedUserPermission.view_products" :key="tableKey" />
      <NoAccess v-else class="no-content-container" />
      <AddProduct
        :key="productKey"
        :width="920"
        :show="show"
        @close="show = false"
        title="Add Product"
        @save="refresh"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableSection from "./TableSection.vue";
import AddProduct from "./AddProduct.vue";
import { MERCHANT_SUB_NAV_ITEMS } from "../../../../constants";
import ProductFilter from "./ProductFilter.vue";

export default {
  name: "MerchantProducts",
  data() {
    return {
      show: false,
      items: MERCHANT_SUB_NAV_ITEMS,
      productKey: 0,
      tableKey: 10,
      filterModalKey: 20,
    };
  },
  methods: {
    ...mapActions({
      setSearchQuery: "merchant/product/setSearchQuery",
      exportProducts: "merchant/product/exportProducts",
      getMerchantTabCount: "merchant/count/getMerchantTabCount",
      setPage: "merchant/product/setPage",
    }),
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    addClick() {
      this.show = true;
      this.productKey += 1;
    },
    refresh() {
      this.show = false;
      this.tableKey += 1;
    },
    async exportClick() {
      await this.exportProducts();
    },
    filterClicked() {
      this.setPage(1);
      this.$refs["productFilterModal"].isActive = false;
      this.tableKey += 1;
    },
    resetFilterClicked() {
      this.$refs["productFilterModal"].isActive = false;
      this.filterModalKey += 1;
      this.tableKey += 1;
    },
  },
  watch: {
    totalProducts() {
      this.items[1].count = this.totalProducts;
    },
  },
  computed: {
    ...mapGetters("merchant/product", ["totalProducts", "isProductFilter"]),
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
    ...mapGetters("merchant/count", ["count"]),
  },
  components: {
    TableSection,
    AddProduct,
    ProductFilter,
  },
  async created() {
    this.setPage(1);
    this.setSearchQuery("");
    await this.getMerchantTabCount();
    this.items[0].count = this.count.orderCount;
    this.items[1].count = this.count.productCount;
    this.items[2].count = this.count.customerCount;
    this.items[3].count = this.count.warehouseCount;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.product-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
}
.filter-marker {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: $primary-color;
  top: -2px;
  right: -3px;
}
</style>
