<template>
  <div class="assign-container pa-12">
    <h1>Assign to Logistics</h1>
    <div class="add-form mt-10">
      <h2 class="mb-3">Logistic information</h2>
      <FavoriteLogisticsSelect
        label="Select Logistics"
        itemText="company_name"
        @focus="getAllLogistics"
        @getLogistics="getFavoriteLogistics"
        :required="true"
        @change="getFormData($event)"
        :value="logistic"
        :key="favKey"
        :loading="listLoading"
      />
    </div>
    <div class="display-flex width-100 justify-end align-center">
      <Button label="Close" type="cancel" width="94px" @click="closeClicked" />
      <Button
        class="ml-3"
        label="Save"
        type="save"
        width="94px"
        @click="saveClicked"
        :disabled="isButtonLoading || isSubmitDisabled()"
        :loading="isButtonLoading"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import FavoriteLogisticsSelect from "./FavoriteLogisticSelect";

export default {
  name: "AssignLogisticModal",
  components: { FavoriteLogisticsSelect },
  mixins: [validationMixin],
  validations: {
    logistic: { required },
  },
  props: ["item", "isReassign"],
  data() {
    return {
      logistic: "",
      isButtonLoading: false,
      favKey: 0,
      listLoading: false,
    };
  },
  methods: {
    ...mapActions({
      getFavoriteLogistics: "merchant/orders/getFavoriteLogistics",
      assignLogistics: "merchant/orders/assignLogistics",
      reAssignLogistics: "merchant/orders/reAssignLogistics",
    }),
    getFormData(value) {
      console.log(value);
      this.logistic = value;
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    async getAllLogistics() {
      this.listLoading = true;
      await this.getFavoriteLogistics();
      this.listLoading = false;
    },
    closeClicked() {
      this.$emit("closeClicked");
    },
    async saveClicked() {
      try {
        this.isButtonLoading = true;
        this.isReassign
          ? await this.reAssignLogistics({
              id: this.item.id,
              logistic_id: this.logistic.id,
              status: "Logistic Assigned",
            })
          : await this.assignLogistics({
              id: this.item.id,
              logistic_id: this.logistic.id,
            });
        this.isButtonLoading = false;
        this.$emit("saveClicked");
      } catch (err) {
        console.log(err);
        this.isButtonLoading = false;
      }
    },
  },
  created() {
    if (this.isReassign) {
      this.logistic = this.item?.logisticdetails;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.assign-container {
  width: 492px;
  height: 311px;
  background: $white-color;
  h1 {
    font-weight: 500;
    font-size: 1.125rem;
    color: #000000;
  }
  .add-form {
    font-weight: 500;
    font-size: 13px;
    color: $secondary-color;
  }
}
</style>
