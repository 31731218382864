<template>
  <div>
    <v-autocomplete
      v-model="editValue"
      :disabled="disabled"
      :items="logisticDataSet"
      filled
      dense
      color="blue-grey lighten-2"
      :label="label"
      :item-text="itemText ? itemText : 'name'"
      return-object
      @focus="focus"
      @change="onSelectChange"
      :loading="loading"
    >
      <template v-slot:selection="data">
        {{ data.item.company_name }}
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <div class="display-flex">
              <div class="mr-3" @click.stop="addFavorite(data.item)">
                <img v-show="data.item.isFav" src="@/assets/icons/star-active.svg" />
                <img v-show="!data.item.isFav" src="@/assets/icons/star-inactive.svg" />
              </div>
              <v-list-item-title v-html="data.item.company_name"></v-list-item-title>
            </div>
            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from "debounce";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FavoriteLogisticsSelect",
  props: [
    "label",
    "value",
    "disabled",
    "required",
    "error",
    "multiple",
    "itemText",
    "loading",
    "isInfinteScroll",
  ],
  data() {
    return {
      editValue: "",
      searchInput: null,
      logisticDataSet: [],
      divider: [{ header: "All Logistics" }],
    };
  },
  methods: {
    ...mapActions({
      setFavoriteLogistics: "merchant/orders/setFavoriteLogistics",
      getFavoriteLogistics: "merchant/orders/getFavoriteLogistics",
    }),
    async addFavorite(item) {
      if (item.isFav) {
        await this.setFavoriteLogistics(item);
        const index = this.logisticDataSet.indexOf(item);
        if (index >= 0) this.logisticDataSet.splice(index, 1);
        item.isFav = false;
        this.logisticDataSet.push(item);
      } else {
        await this.setFavoriteLogistics(item);
        const index = this.logisticDataSet.indexOf(item);
        if (index >= 0) this.logisticDataSet.splice(index, 1);
        item.isFav = true;
        this.logisticDataSet.splice(1, 0, item);
      }
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit("onScroll");
      }
    },
    onSelectChange(value) {
      this.$emit("change", value);
    },
    async focus() {
      this.logisticDataSet = [{ header: "Favourites" }];
      // await this.$emit("focus");
      await this.getFavoriteLogistics();
      this.logisticDataSet = [...this.logisticDataSet, ...this.logistics.fav];
      this.logisticDataSet = [...this.logisticDataSet, ...this.divider];
      this.logisticDataSet = [...this.logisticDataSet, ...this.logistics.all];
    },
  },
  mounted() {
    this.editValue = this.value;
    this.logisticDataSet.push(this.value);
  },
  watch: {
    value() {
      this.editValue;
    },
    searchInput: debounce(function () {
      this.$emit("onSearch", this.searchInput);
    }, 200),
  },
  async created() {
    this.logisticDataSet = [{ header: "Favourites" }];
    await this.$emit("focus");

    this.logisticDataSet = [...this.logisticDataSet, ...this.logistics.fav];
    this.logisticDataSet = [...this.logisticDataSet, ...this.divider];
    this.logisticDataSet = [...this.logisticDataSet, ...this.logistics.all];
  },
  computed: {
    ...mapGetters("merchant/orders", ["logistics"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  border-radius: 1px;
  padding-bottom: 10px;
  min-height: 64px !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep .textAreaError.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
  min-height: 64px !important;
}
::v-deep .textAreaError.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-input input {
  max-height: unset !important;
}
</style>
