var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-container pl-14 pr-14"},[_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete Product","cancel":"Cancel","content":"Are you sure you want to delete the selected Product? ","accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}}),(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center"}):_c('div',[(_vm.totalProducts === 0)?_c('div',{staticClass:"no-content-container"},[_c('NoContent')],1):_c('v-data-table',{attrs:{"headers":_vm.productHeaders,"items":_vm.products,"expanded":_vm.expanded,"item-key":"id","options":{ page: _vm.page, itemsPerPage: _vm.limit },"server-items-length":_vm.totalProducts,"hide-default-footer":""},on:{"update:page":function($event){return _vm.updatePagination($event)},"update:expanded":function($event){_vm.expanded=$event},"update:items-per-page":function($event){return _vm.updatePerPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":"","items-per-page-options":_vm.itemsPerPage,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.product_image)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.product_image)),"name":item.product_name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.product_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.product_name ? item.product_name : "-")+" ")])],1)]}},{key:"item.product_category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.product_category ? item.product_category : "-")+" ")])]}},{key:"item.soldcount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.soldcount ? item.soldcount : "0")+" ")])]}},{key:"item.product_width",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.product_length ? item.product_length : "0")+" x "+_vm._s(item.product_width ? item.product_width : "0")+" x "+_vm._s(item.product_height ? item.product_height : "0")+" ")])]}},{key:"item.product_code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.product_code ? item.product_code : "-")+" ")])]}},{key:"item.product_brand",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.product_brand ? item.product_brand : "-")+" ")])]}},{key:"item.consumption",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.consumption ? item.consumption : "-")+" ")])]}},{key:"item.product_description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"name ellipsis"},on),[_vm._v(" "+_vm._s(item.product_description ? item.product_description : "-")+" ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(item.product_description ? item.product_description : "-")+" ")])])]}},{key:"item.uom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.uom ? item.uom : "-"))])]}},{key:"item.product_price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name price"},[(item.currency === 'SGD')?_c('span',[_vm._v("S$")]):_vm._e(),(item.currency === 'INR')?_c('span',[_vm._v("₹")]):_vm._e(),(item.currency === 'USD')?_c('span',[_vm._v("$")]):_vm._e(),_vm._v(" "+_vm._s(item.product_price ? item.product_price : "-")+" ")])]}},{key:"item.actions",fn:function(props){return [(props.item.actions)?_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('v-edit-dialog',{attrs:{"return-value":props.item.action},on:{"update:returnValue":function($event){return _vm.$set(props.item, "action", $event)},"update:return-value":function($event){return _vm.$set(props.item, "action", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('InnerMoreOptions',{on:{"editClicked":function($event){return _vm.editClicked(props.item)},"deleteClicked":function($event){return _vm.deleteClicked(props.item)}}})]},proxy:true}],null,true)},[_c('div',{staticClass:"more-options exact-center ml-2 cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])])],1):_vm._e()]}}])})],1),_c('AddProduct',{key:_vm.addKey,attrs:{"width":920,"show":_vm.showAddProduct,"title":"Edit Product","item":_vm.selectedItem,"isEdit":true},on:{"close":function($event){_vm.showAddProduct = false},"save":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }