<template>
  <v-dialog :value="show" persistent :max-width="width">
    <div class="pl-6 pr-6" :key="dialogKey">
      <!-- title -->
      <div
        class="add-user-title pt-8 display-flex width-100 align-center justify-space-between"
      >
        {{ title }}
        <v-btn @click="onCancel" icon dark>
          <img src="@/assets/icons/close.svg" />
        </v-btn>
      </div>

      <!-- Customer information -->
      <div class="pt-6 justify-space-between width-100">
        <div class="sub-title">Customer Information</div>
        <div v-if="autoFilled">
          <div
            class="sub-title cursor-pointer"
            v-if="fieldDisabled"
            @click="fieldDisabled = false"
          >
            Edit info
          </div>
          <div
            v-else
            :class="{ 'customer-save-disabled': isCustomerSubmitDisabled() }"
            @click="saveCustomerClicked"
          >
            <Spinner v-if="customerLoading" size="5" />
            <div class="sub-title cursor-pointer" v-else>Save info</div>
          </div>
        </div>
      </div>
      <v-row no-gutters class="mt-4">
        <v-col>
          <v-row no-gutters>
            <v-col cols="3">
              <DialCode
                :value="this.formData.extension"
                @change="getFormData($event, 'extension')"
                :disabled="fieldDisabled"
              />
            </v-col>
            <v-col cols="9">
              <Combobox
                :icon="true"
                :required="true"
                :value="formData.contact_number"
                label="Mobile No."
                itemText="contact_number"
                @focus="getCustomersList"
                :items="customersDataSet"
                @onChange="getCustomerData($event, 'contact_number')"
                @onScroll="onCustomerScroll"
                @onSearch="onCustomerSearch"
                :isInfinteScroll="true"
                :loading="customerFieldLoading"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-3">
          <TextField
            label="First Name"
            :value="formData.user_first_name"
            :required="true"
            :disabled="fieldDisabled"
            @change="getFormData($event, 'user_first_name')"
        /></v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <TextField
            label="Last Name"
            :value="formData.user_last_name"
            :required="true"
            :disabled="fieldDisabled"
            @change="getFormData($event, 'user_last_name')"
          />
        </v-col>
        <v-col class="ml-3">
          <TextField
            label="Email"
            :value="formData.email_address"
            :disabled="fieldDisabled"
            @change="getFormData($event, 'email_address')"
            :errorMessages="emailErrors"
            :error="emailErrors && emailErrors.length > 0"
            @input="$v.formData.email_address.$touch()"
            @blur="$v.formData.email_address.$touch()"
        /></v-col>
      </v-row>
      <!-- Customer Delivery address -->
      <div class="sub-title">Customer Delivery address</div>
      <v-row no-gutters class="mt-4">
        <v-col>
          <TextField
            label="Post Code"
            :value="formData.postalcode"
            :required="true"
            :disabled="fieldDisabled"
            @input="getFormData($event, 'postalcode')"
            input-type="Number"
            min="0"
            :debounce="true"
            :key="postalcodeKey"
        /></v-col>
        <v-col class="ml-3"
          ><TextField
            label="Building Name/Block Number"
            :value="formData.block_number"
            :disabled="fieldDisabled"
            :required="true"
            @change="getFormData($event, 'block_number')"
        /></v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          ><TextField
            label="Floor - Unit Number"
            :value="formData.floor_number"
            :required="true"
            :disabled="fieldDisabled"
            @change="getFormData($event, 'floor_number')"
        /></v-col>
        <v-col class="ml-3">
          <TextField
            label="Address"
            :required="true"
            :disabled="fieldDisabled"
            :value="formData.address"
            @change="getFormData($event, 'address')"
            :key="addressKey"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          ><SelectField
            label="Country"
            :required="true"
            :disabled="fieldDisabled"
            :value="formData.country"
            :data-set="countryDataSet"
            @change="getFormData($event, 'country')"
            :key="countryKey"
        /></v-col>
        <v-col class="ml-3"
          ><TextField
            label="State"
            :disabled="fieldDisabled"
            :value="formData.state"
            @change="getFormData($event, 'state')"
            :key="cityKey"
        /></v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="col-6"
          ><TextField
            label="City"
            :disabled="fieldDisabled"
            :value="formData.cityortown"
            @change="getFormData($event, 'cityortown')"
            :key="cityKey"
        /></v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <div class="locate-in-map justify-space-between mb-3">
            <div>Locate in Map</div>
            <div>
              Latitude :
              {{
                shippingLocation.lat ? shippingLocation.lat : shippingCenter.lat
              }}
              | Longitude :
              {{
                shippingLocation.lng ? shippingLocation.lng : shippingCenter.lng
              }}
            </div>
          </div>
          <GmapMap
            :center="shippingCenter"
            :zoom="mapZoom"
            map-type-id="roadmap"
            style="height: 200px"
            @click="shippingMarkerDragged"
            :key="shippingMapKey"
          >
            <GmapMarker
              :position="shippingLocation"
              :clickable="true"
              :draggable="true"
              @click="shippingCenter = shippingLocation"
              @dragend="shippingMarkerDragged"
              :icon="{ url: require('@/assets/icons/mylocation.svg') }"
            />
          </GmapMap>
        </v-col>
      </v-row>
      <v-row no-gutters class="sub-title billing-address mt-3 pb-3 pt-3">
        <v-col>
          <span>Billing Address</span>
        </v-col>
        <v-col
          ><div class="width-100 justify-end">
            <v-checkbox
              color="#ff003f"
              label="Same as delivery address"
              class="mr-5"
              :input-value="sameAsShippingAddress"
              @change="onChangeAddressToggle"
            /></div
        ></v-col>
      </v-row>
      <!-- Billing Address -->
      <div class="sub-title mt-4" v-if="isBillingAddress">Billing Address</div>
      <v-row no-gutters class="mt-4" v-if="isBillingAddress">
        <v-col
          ><TextField
            label="Post Code"
            :value="billingFormData.billing_postalcode"
            :required="true"
            @input="getBillingFormData($event, 'billing_postalcode')"
            input-type="Number"
            min="0"
            :debounce="true"
            :key="billingPostalcodeKey"
        /></v-col>
        <v-col class="ml-3"
          ><TextField
            label="Building Name/Block Number"
            :value="billingFormData.billing_block_number"
            :required="true"
            @change="getBillingFormData($event, 'billing_block_number')"
        /></v-col>
      </v-row>
      <v-row v-if="isBillingAddress" no-gutters>
        <v-col
          ><TextField
            label="Floor - Unit Number"
            :value="billingFormData.billing_floor_number"
            :required="true"
            @change="getBillingFormData($event, 'billing_floor_number')"
        /></v-col>
        <v-col class="ml-3">
          <TextField
            label="Address"
            :required="true"
            :value="billingFormData.billing_address"
            @change="getBillingFormData($event, 'billing_address')"
            :key="billingAddressKey"
          />
        </v-col>
      </v-row>
      <v-row v-if="isBillingAddress" no-gutters>
        <v-col
          ><SelectField
            label="Country"
            :required="true"
            :value="billingFormData.billing_country"
            :data-set="countryDataSet"
            @change="getBillingFormData($event, 'billing_country')"
            :key="billingCountryKey"
        /></v-col>
        <v-col class="ml-3">
          <TextField
            label="State"
            :value="billingFormData.billing_state"
            @change="getBillingFormData($event, 'billing_state')"
            :key="billingStateKey"
          />
        </v-col>
      </v-row>
      <v-row v-if="isBillingAddress" no-gutters>
        <v-col class="col-6">
          <TextField
            label="City"
            :value="billingFormData.billing_cityortown"
            @change="getBillingFormData($event, 'billing_cityortown')"
            :key="billingCityKey"
          />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="isBillingAddress">
        <v-col>
          <div class="locate-in-map justify-space-between mb-3">
            <div>Locate in Map</div>
            <div>
              Latitude :
              {{
                billingLocation.lat ? billingLocation.lat : billingCenter.lat
              }}
              | Longitude :
              {{
                billingLocation.lng ? billingLocation.lng : billingCenter.lng
              }}
            </div>
          </div>
          <GmapMap
            :center="billingCenter"
            :zoom="mapZoom"
            map-type-id="roadmap"
            style="height: 200px"
            :key="billingMapKey"
            @click="billingMarkerDragged"
          >
            <GmapMarker
              :position="billingLocation"
              :clickable="true"
              :draggable="true"
              @click="shippingCenter = billingLocation"
              @dragend="billingMarkerDragged"
              :icon="{ url: require('@/assets/icons/mylocation.svg') }"
            />
          </GmapMap>
        </v-col>
      </v-row>

      <!-- Product Information -->
      <div class="sub-title mt-6">Product Information</div>
      <div v-for="(item, i) in productFormData" :key="i">
        <div
          v-if="i !== 0"
          @click="removeProducts(i)"
          class="sub-title mt-4 add-another-product cursor-pointer"
        >
          Remove
        </div>

        <v-row no-gutters class="mt-4 pt-4 pb-4 product-line">
          <v-col class="col-3"
            ><ImageUpload
              :editDisable="true"
              placeholder="product"
              label="Product Image"
              :src="
                item.productImgSrc ? `${serverUrl}/${item.productImgSrc}` : ''
              "
          /></v-col>
          <v-col class="ml-4">
            <v-row no-gutters>
              <v-col
                ><SelectField
                  @change="
                    getProductFormData($event, {
                      fieldName: 'category',
                      index: i,
                    })
                  "
                  label="Category"
                  :required="true"
                  :value="item.category"
                  :data-set="categories"
                  @focus="getAllCategories"
              /></v-col>
              <v-col class="ml-3"
                ><SelectField
                  @change="
                    getProductFormData($event, {
                      fieldName: 'product_id',
                      index: i,
                    })
                  "
                  label="Name"
                  itemText="product_name"
                  :required="true"
                  :value="item.product_id"
                  :disabled="item.productDisabled"
                  :data-set="item.productDataSet"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                ><TextField
                  @change="
                    getProductFormData($event, {
                      fieldName: 'product_quantity',
                      index: i,
                    })
                  "
                  label="Quantity"
                  :required="true"
                  :value="item.product_quantity"
                  inputType="Number"
                  min="0"
              /></v-col>
              <v-col class="ml-3">
                <DatePicker
                  icon="calendar"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  :required="true"
                  :dateValue="item.expected_delivery_date"
                  @change="
                    getProductFormData($event, {
                      fieldName: 'expected_delivery_date',
                      index: i,
                    })
                  "
                  label="Expected Delivery"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <Combobox
                  :icon="false"
                  :required="true"
                  :value="item.uom"
                  label="UOM"
                  itemText="uom"
                  :items="uomDataSet"
                  @focus="getAllProductUOM"
                  @onChange="
                    getProductFormData($event, { fieldName: 'uom', index: i })
                  "
                />
              </v-col>
              <v-col class="ml-3"
                ><SelectField
                  @change="
                    getProductFormData($event, {
                      fieldName: 'warehouse_id',
                      index: i,
                    })
                  "
                  label="Warehouse"
                  :required="true"
                  itemText="warehouse_nameWithAddress"
                  :value="item.warehouse_id"
                  :data-set="item.warehouseDataSet"
                  :loading="warehouseLoading"
                  :isInfinteScroll="true"
                  @onScroll="onWarehouseScroll(item)"
                  @onSearch="onWarehouseSearch($event, item)"
                  @focus="getAllWarehouses(item)"
              /></v-col>
            </v-row>
            <v-row class="no-gutters sub-title">Others</v-row>
            <v-row class="no-gutters">
              <v-col>
                <v-checkbox
                  color="#ff003f"
                  label="Installation Required"
                  :input-value="item.installation_required"
                  @change="
                    getProductFormData($event, {
                      fieldName: 'installation_required',
                      index: i,
                    })
                  "
              /></v-col>
              <v-col>
                <v-checkbox
                  color="#ff003f"
                  label="Cleaning"
                  :input-value="item.cleaning"
                  @change="
                    getProductFormData($event, {
                      fieldName: 'cleaning',
                      index: i,
                    })
                  "
              /></v-col>
              <v-col>
                <v-checkbox
                  color="#ff003f"
                  label="Aircon Servicing"
                  :input-value="item.aircon_servicing"
                  @change="
                    getProductFormData($event, {
                      fieldName: 'aircon_servicing',
                      index: i,
                    })
                  "
              /></v-col>
            </v-row>
            <v-row class="no-gutters">
              <v-col>
                <v-checkbox
                  color="#ff003f"
                  label="Catering"
                  :input-value="item.catering"
                  @change="
                    getProductFormData($event, {
                      fieldName: 'catering',
                      index: i,
                    })
                  "
              /></v-col>
              <v-col>
                <v-checkbox
                  color="#ff003f"
                  label="Pest Control"
                  :input-value="item.pest_control"
                  @change="
                    getProductFormData($event, {
                      fieldName: 'pest_control',
                      index: i,
                    })
                  "
              /></v-col>
              <v-col>
                <v-checkbox
                  color="#ff003f"
                  label="General Servicing"
                  :input-value="item.general_servicing"
                  @change="
                    getProductFormData($event, {
                      fieldName: 'general_servicing',
                      index: i,
                    })
                  "
              /></v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div
        @click="addAnotherProduct"
        class="sub-title add-another-product cursor-pointer mt-4"
      >
        + Add Another Product
      </div>

      <!-- Footer section -->
      <v-row class="justify-end footer-section pb-5">
        <div class="justify-end">
          <Button label="Close" type="cancel" width="94px" @click="onCancel" />
          <Button
            class="ml-3"
            label="Save"
            type="save"
            width="94px"
            @click="onSave"
            :disabled="isSubmitDisabled() || isLoading"
            :loading="isLoading"
          />
        </div>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { Country, State } from "country-state-city";
import { DEFAULT_COUNTRY, SERVER_URL } from "../../../../constants";
import {
  getAddressData,
  getAddressFromLatLng,
} from "../../../services/utils.js";
export default {
  name: "AddOrder",
  props: ["show", "width", "title"],
  mixins: [validationMixin],
  validations: {
    formData: {
      email_address: { email },
      user_first_name: { required },
      user_last_name: { required },
      contact_number: { required },
      country: { required },
      postalcode: { required },
      block_number: { required },
      floor_number: { required },
      address: { required },
      extension: { required },
    },
    productFormData: {
      $each: {
        product_id: { required },
        category: { required },
        expected_delivery_date: { required },
        product_quantity: { required },
        warehouse_id: { required },
        uom: { required },
        installation_required: {},
        cleaning: {},
        pest_control: {},
        general_servicing: {},
        catering: {},
        aircon_servicing: {},
      },
    },
    billingFormData: {
      billing_postalcode: { required },
      billing_country: { required },
      billing_cityortown: {},
      billing_address: { required },
      billing_block_number: { required },
      billing_floor_number: { required },
      billing_state: { required },
    },
  },
  data() {
    return {
      formData: {
        contact_number: "",
        user_first_name: "",
        user_last_name: "",
        email_address: "",
        cityortown: "",
        state: "",
        country: "",
        postalcode: "",
        block_number: "",
        floor_number: "",
        address: "",
        extension: "65",
      },
      productFormData: [
        {
          product_id: "",
          category: "",
          product_quantity: "",
          expected_delivery_date: "",
          warehouse_id: "",
          uom: "",
          installation_required: false,
          cleaning: false,
          pest_control: false,
          general_servicing: false,
          catering: false,
          aircon_servicing: false,
          productDisabled: true,
          productImgSrc: "",
          productDataSet: [],
          warehouseDataSet: [],
        },
      ],
      billingFormData: {
        billing_postalcode: "",
        billing_country: "",
        billing_cityortown: "",
        billing_block_number: "",
        billing_floor_number: "",
        billing_address: "",
        billing_state: "",
      },
      serverUrl: SERVER_URL,
      minDate: new Date().toISOString().substr(0, 10),
      maxDate: "",
      selectedCustomerId: "",
      sameAsShippingAddress: true,
      customerLoading: false,
      fieldDisabled: false,
      autoFilled: false,
      dialogKey: 0,
      markers: [],
      countryDataSet: [],
      stateDataSet: [],
      cityDataSet: [],
      isLoading: false,
      isBillingAddress: false,
      showBillingAddress: true,
      mapZoom: 12,
      //Shipping Address Map Data
      shippingLocation: { lat: 0, lng: 0 },
      shippingCenter: { lat: 1.3521, lng: 103.8198 },
      shippingMapKey: 30,
      countryKey: 50,
      stateKey: 60,
      cityKey: 70,
      postalcodeKey: 130,
      addressKey: 140,
      //Billing Address Map Data
      billingLocation: { lat: 0, lng: 0 },
      billingCenter: { lat: 1.3521, lng: 103.8198 },
      billingMapKey: 40,
      billingCountryKey: 80,
      billingPostalcodeKey: 120,
      billingStateKey: 90,
      billingCityKey: 100,
      billingAddressKey: 150,
      customersDataSet: [],
      warehouseDataSet: [],
      customerFieldLoading: false,
      warehouseLoading: false,
    };
  },
  methods: {
    ...mapActions({
      updateCustomer: "merchant/customers/updateCustomer",
      getProducts: "merchant/product/getProducts",
      getCustomers: "merchant/customers/getOrderCustomers",
      addOrder: "merchant/orders/addOrder",
      getProductUOM: "merchant/orders/getProductUOM",
      getProductCategories: "merchant/product/getProductCategories",
      getProductsByCategory: "merchant/product/getProductsByCategory",
      setPage: "merchant/customers/setPage",
      setSearchQuery: "merchant/customers/setSearchQuery",
      getWarehouses: "merchant/warehouse/getWarehouses",
      setWarehousePage: "merchant/warehouse/setPage",
      setWarehouseSearch: "merchant/warehouse/setSearchQuery",
    }),
    async shippingMarkerDragged(event) {
      if (this.fieldDisabled) this.fieldDisabled = false;
      this.shippingLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      await getAddressFromLatLng(this.shippingLocation, (address) => {
        const country = this.countryDataSet.find(
          (obj) => obj.name === address.country
        );
        this.formData.postalcode = address.postal_code;
        this.formData.country = country;
        this.formData.state = address.administrative_area_level_1
          ? address.administrative_area_level_1
          : address.locality;
        this.formData.cityortown = address.administrative_area_level_2;
        this.formData.address = address.fullAddress ? address.fullAddress : "";
        this.postalcodeKey += 1;
        this.countryKey += 1;
        this.stateKey += 1;
        this.cityKey += 1;
        this.addressKey += 1;
      });
      if (this.sameAsShippingAddress) {
        this.setBillingAddressAsShipping();
      }
    },
    async billingMarkerDragged(event) {
      this.billingLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      await getAddressFromLatLng(this.billingLocation, (address) => {
        const country = this.countryDataSet.find(
          (obj) => obj.name === address.country
        );
        this.billingFormData.billing_postalcode = address.postal_code;
        this.billingFormData.billing_country = country;
        this.billingFormData.billing_state = address.administrative_area_level_1
          ? address.administrative_area_level_1
          : address.locality;
        this.billingFormData.billing_cityortown =
          address.administrative_area_level_2;
        this.billingFormData.billing_address = address.fullAddress
          ? address.fullAddress
          : "";
        this.billingCountryKey += 1;
        this.billingPostalcodeKey += 1;
        this.billingStateKey += 1;
        this.billingCityKey += 1;
        this.billingAddressKey += 1;
      });
    },
    getToday() {
      return moment().format();
    },
    //1. Customer Information
    //Function to get all customers
    async getCustomersList() {
      this.setPage(1);
      this.setSearchQuery("");
      await this.getCustomers();
      this.customersDataSet = this.customers;
    },
    //Function to update customer details
    async saveCustomerClicked() {
      this.customerLoading = true;
      this.formData.country = this.formData.country
        ? this.formData.country.name
        : "";
      this.formData.latitude = this.shippingLocation?.lat;
      this.formData.longitude = this.shippingLocation?.lng;
      this.formData.email_address = this.formData.email_address
        ? this.formData.email_address
        : null;
      try {
        await this.updateCustomer({
          id: this.selectedCustomerId,
          data: this.formData,
        });
        this.customerLoading = false;
        this.fieldDisabled = true;
        this.autoFilled = true;
      } catch (err) {
        console.log(err);
        this.customerLoading = false;
      }
    },
    //Function to auto fill customer details data
    getCustomerData(value, field) {
      if (value.id) {
        Object.keys(value).map((key) => {
          if (key === "contact_number") this.getFormData(value, key);
          this.selectedCustomerId = value["id"];
          this.shippingLocation.lat = value["latitude"];
          this.shippingLocation.lng = value["longitude"];
          this.shippingCenter = this.shippingLocation;
          this.mapZoom = 15;
          this.shippingMapKey += 1;
          const country = this.countryDataSet.find(
            (obj) => obj.name === value["country"]
          );
          this.getFormData(key === "country" ? country : value[key], key, true);
        });
        this.fieldDisabled = true;
        this.autoFilled = true;
        this.dialogKey += 1;
      } else {
        if (this.autoFilled && this.fieldDisabled) {
          this.formData.user_first_name = "";
          this.formData.user_last_name = "";
          this.formData.email_address = "";
          this.formData.postalcode = "";
          this.formData.block_number = "";
          this.formData.floor_number = "";
          this.formData.address = "";
          this.formData.extension = "65";
        }
        this.getFormData(value, field);
        this.selectedCustomerId = "";
        this.fieldDisabled = false;
        this.autoFilled = false;
        const country = this.countryDataSet.find(
          (obj) => obj.name === DEFAULT_COUNTRY
        );
        this.formData.country = country;
        this.formData.state = DEFAULT_COUNTRY;
        this.formData.cityortown = DEFAULT_COUNTRY;
        this.billingFormData.billing_postalcode = "";
        this.billingFormData.billing_country = country.name;
        this.billingFormData.billing_cityortown = DEFAULT_COUNTRY;
        this.billingFormData.billing_block_number = "";
        this.billingFormData.billing_floor_number = "";
        this.billingFormData.billing_address = "";
        this.dialogKey += 1;
      }
    },
    //Function to check customer validation
    isCustomerSubmitDisabled() {
      return this.$v.formData.$invalid;
    },
    //Function to set customer form data
    async getFormData(value, fieldName, isCustomerFetch) {
      if (fieldName === "postalcode" && !isCustomerFetch) {
        await getAddressData(value, (address, latlng) => {
          const country = this.countryDataSet.find(
            (obj) => obj.name === address.country
          );
          this.formData.country = country;
          this.formData.state = address.administrative_area_level_1
            ? address.administrative_area_level_1
            : address.locality;
          this.formData.cityortown = address.administrative_area_level_2;
          this.formData.address = address.fullAddress
            ? address.fullAddress
            : "";
          this.shippingLocation = latlng;
          this.shippingCenter = latlng;
          this.shippingMapKey += 1;
          this.countryKey += 1;
          this.stateKey += 1;
          this.cityKey += 1;
          this.addressKey += 1;
        });
        this.formData[fieldName] = value;
        return;
      }
      // if (fieldName === "country") {
      //   this.formData[fieldName] = value;
      //   this.formData = { ...this.formData };
      //   if (this.sameAsShippingAddress) {
      //     this.setBillingAddressAsShipping();
      //   }
      //   return;
      // }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      if (this.sameAsShippingAddress) {
        this.setBillingAddressAsShipping();
      }
    },
    //2. Biilling Address Information
    //Toggle to set Shipping address as billing address
    onChangeAddressToggle(value) {
      if (!value) {
        this.showBillingAddress = false;
        this.isBillingAddress = true;
        this.billingFormData.billing_address = "";
        this.billingFormData.billing_postalcode = "";
        this.billingFormData.billing_block_number = "";
        this.billingFormData.billing_floor_number = "";
        this.billingFormData.billing_state = DEFAULT_COUNTRY;
        const country = this.countryDataSet.find(
          (obj) => obj.name === DEFAULT_COUNTRY
        );
        this.billingFormData.billing_country = country;
        this.billingFormData.billing_cityortown = DEFAULT_COUNTRY;
        this.billingLocation = { lat: 0, lng: 0 };
        this.billingMapKey += 1;
      } else {
        this.setBillingAddressAsShipping();
        this.showBillingAddress = true;
        this.isBillingAddress = false;
      }
      this.sameAsShippingAddress = value;
    },
    //Function to set Shipping address as billing address
    setBillingAddressAsShipping() {
      this.billingFormData.billing_address = this.formData.address
        ? this.formData.address
        : "";
      this.billingFormData.billing_postalcode = this.formData.postalcode
        ? this.formData.postalcode
        : "";
      let country = "";
      if (this.formData.country.name) {
        country = this.countryDataSet.find(
          (obj) => {
            if (obj.name === this.formData.country.name) return obj;
          }
          // obj.name === this.formData.country.name
          //   ? this.formData.country.name
          //   : this.formData.country
        );
      } else {
        country = this.formData.country;
      }
      this.billingFormData.billing_country = country;
      this.billingFormData.billing_cityortown = this.formData.cityortown
        ? this.formData.cityortown
        : "";
      this.billingFormData.billing_state = this.formData.state
        ? this.formData.state
        : "";
      this.billingFormData.billing_block_number = this.formData.block_number
        ? this.formData.block_number
        : "";
      this.billingFormData.billing_floor_number = this.formData.floor_number
        ? this.formData.floor_number
        : "";
      this.billingLocation = this.shippingLocation
        ? this.shippingLocation
        : { lat: 0, lng: 0 };
      this.billingCenter = this.shippingLocation;
      this.billingMapKey += 1;
    },
    changeBillingAddress() {
      this.isBillingAddress = !this.isBillingAddress;
      this.showBillingAddress = !this.showBillingAddress;
    },
    //Function to check billing validation
    isBillingSubmitDisabled() {
      return this.$v.billingFormData.$invalid;
    },
    //Function to set Billing address form data
    async getBillingFormData(value, fieldName) {
      if (fieldName === "billing_postalcode") {
        await getAddressData(value, (address, latlng) => {
          const country = this.countryDataSet.find(
            (obj) => obj.name === address.country
          );
          this.stateDataSet = State.getStatesOfCountry(country.isoCode);
          const state = address.administrative_area_level_1
            ? address.administrative_area_level_1
            : address.locality;
          this.billingFormData.billing_country = country;
          this.billingFormData.billing_state = state;
          this.billingFormData.billing_cityortown =
            address.administrative_area_level_2;
          this.billingFormData.billing_address = address.fullAddress
            ? address.fullAddress
            : "";
          this.billingLocation = latlng;
          this.billingCenter = latlng;
          this.billingMapKey += 1;
          this.billingCountryKey += 1;
          this.billingStateKey += 1;
          this.billingCityKey += 1;
          this.billingAddressKey += 1;
        });
        this.billingFormData[fieldName] = value;
        return;
      }
      // if (fieldName === "billing_country") {
      //   this.billingFormData[fieldName] = value;
      //   return;
      // }
      this.billingFormData[fieldName] = value;
      this.billingFormData = { ...this.billingFormData };
    },
    //3. Product Information
    //Function to check Product data validation
    isProductSubmitDisabled() {
      return this.$v.productFormData.$invalid;
    },
    async getAllCategories() {
      await this.getProductCategories();
    },
    async getAllProductUOM() {
      await this.getProductUOM();
    },
    //Function to set Product details form data
    async getProductFormData(value, { fieldName, index }) {
      if (fieldName === "category") {
        await this.getProductsByCategory({ category: value });
        this.productFormData[index]["productDataSet"] = this.products;
        this.productFormData[index]["productDisabled"] = false;
        this.productFormData[index][fieldName] = value;
        return;
      }
      if (fieldName === "warehouse_id") {
        this.productFormData[index][fieldName] = value.id;
        return;
      }
      if (fieldName === "product_id") {
        this.productFormData[index]["productImgSrc"] = value.product_image;
        this.productFormData[index][fieldName] = value.id;
        return;
      }
      this.productFormData[index][fieldName] = value;
    },
    addAnotherProduct() {
      this.productFormData.push({
        product_id: "",
        category: "",
        product_quantity: "",
        expected_delivery_date: "",
        warehouse_id: "",
        uom: "",
        installation_required: false,
        cleaning: false,
        pest_control: false,
        general_servicing: false,
        catering: false,
        aircon_servicing: false,
        productDisabled: true,
        productDataSet: [],
      });
    },
    removeProducts(index) {
      this.productFormData.splice(index, 1);
    },

    //GENERAL METHODS
    async getAllWarehouses(item) {
      this.warehouseLoading = true;
      this.setWarehousePage(1);
      this.setWarehouseSearch("");
      await this.getWarehouses();
      item.warehouseDataSet = this.warehouses;
      this.warehouseLoading = false;
    },
    async onWarehouseScroll(item) {
      if (this.totalWarehouse > this.warehouseDataSet.length) {
        this.warehouseLoading = true;
        this.setWarehousePage(this.warehousePage + 1);
        await this.getWarehouses();
        this.warehouseLoading = false;
        item.warehouseDataSet = [...item.warehouseDataSet, ...this.warehouses];
      }
    },
    async onWarehouseSearch(value, item) {
      this.warehouseLoading = true;
      this.setWarehousePage(1);
      this.setWarehouseSearch(value);
      await this.getWarehouses();
      this.warehouseLoading = false;
      item.warehouseDataSet = this.warehouses;
    },
    onCancel() {
      this.$emit("close");
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    async onSave() {
      this.isLoading = true;
      try {
        const billingArray = [];
        billingArray.push({
          block_number: this.billingFormData.billing_block_number
            ? this.billingFormData.billing_block_number
            : "",
          floor_number: this.billingFormData.billing_floor_number
            ? this.billingFormData.billing_floor_number
            : "",
          postalcode: this.billingFormData.billing_postalcode
            ? this.billingFormData.billing_postalcode
            : "",
          country: this.billingFormData.billing_country
            ? this.billingFormData.billing_country.name
            : "",
          state: this.billingFormData.billing_state
            ? this.billingFormData.billing_state
            : "",
          cityortown: this.billingFormData.billing_cityortown
            ? this.billingFormData.billing_cityortown
            : "",
          address: this.billingFormData.billing_address
            ? this.billingFormData.billing_address
            : "",
          latitude: this.billingLocation.lat,
          longitude: this.billingLocation.lng,
        });
        this.formData.company_id = this.loggedUser
          ? this.loggedUser.company_id
          : "";
        this.formData.country = this.formData.country
          ? this.formData.country.name
          : "";
        this.formData.email_address = this.formData.email_address
          ? this.formData.email_address
          : null;
        this.formData.latitude = this.shippingLocation?.lat;
        this.formData.longitude = this.shippingLocation?.lng;
        this.formData.productArray = this.productFormData;
        this.formData.billingAddressArray = billingArray;
        if (this.formData.state === "") delete this.formData.state;
        await this.addOrder(this.formData);
        this.isLoading = false;
        this.$emit("saveClicked");
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    setDefaultCountry() {
      const country = this.countryDataSet.find(
        (obj) => obj.name === DEFAULT_COUNTRY
      );
      this.formData.country = country.name;
      this.formData.state = DEFAULT_COUNTRY;
      this.formData.cityortown = DEFAULT_COUNTRY;
      this.setBillingAddressAsShipping();
      this.stateDataSet = State.getStatesOfCountry(country.isoCode);
    },
    async onCustomerScroll() {
      if (this.totalCustomers > this.customersDataSet.length) {
        this.customerFieldLoading = true;
        this.setPage(this.page + 1);
        await this.getCustomers();
        this.customerFieldLoading = false;
        this.customersDataSet = [...this.customersDataSet, ...this.customers];
      }
    },
    async onCustomerSearch(value) {
      this.customerFieldLoading = true;
      this.setSearchQuery(value);
      this.setPage(1);
      await this.getCustomers();
      this.customerFieldLoading = false;
      this.customersDataSet = [...this.customersDataSet, ...this.customers];
    },
  },
  computed: {
    ...mapGetters("merchant/authentication", ["loggedUser"]),
    ...mapGetters("merchant/customers", [
      "customers",
      "totalCustomers",
      "page",
    ]),
    ...mapGetters("merchant/warehouse", [
      "warehouses",
      "totalWarehouse",
      "warehousePage",
    ]),
    ...mapGetters("merchant/product", ["products", "categories"]),
    ...mapGetters("merchant/orders", ["uomDataSet"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.formData.email_address.$dirty) return errors;
      !this.$v.formData.email_address.email &&
        errors.push("Must be valid e-mail");
      return errors;
    },
  },
  async created() {
    this.countryDataSet = Country.getAllCountries();
    await this.setDefaultCountry();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.checkbox-container {
  v-checkbox {
    width: 100px;
  }
}
.v-dialog > * {
  background: #ffffff !important;
  min-height: 550px;
}
.customer-save-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.sub-title {
  color: $secondary-color;
  font-weight: 500;
  font-size: 13px;
}
.billing-address {
  color: #ff003f;
  border-bottom: 1px solid #e5e5e5;
}
.add-user-title {
  color: #000000;
  font-size: 16px;
}
.footer-section {
  width: 100%;
}
.locate-in-map {
  color: #808080;
  font-size: 12px;
}
::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: #000000 !important;
  font-size: 14px !important;
}
.product-line {
  border-bottom: 1px solid #e5e5e5;
}
.add-another-product {
  color: #ff003f;
}
</style>
