<template>
  <div class="customer-card mb-4 width-100">
    <div class="header display-flex width-100 justify-space-between align-center mb-6">
      <h1>Customers</h1>
      <img
        src="@/assets/icons/dash-customer-img.svg"
        alt="dashboard-customer"
        height="72"
      />
    </div>
    <div class="details-container width-100 justify-space-between display-flex">
      <!-- Today -->
      <div class="detail display-flex align-center">
        <div class="count display-flex flex-col">
          <h1>{{ customersCount.todayCustomersCount }}</h1>
          <h2 class="mt-2">Today</h2>
        </div>
        <div class="graph ml-6 display-flex">
          <img
            src="@/assets/icons/dash-graph-img.svg"
            alt="dashboard-graph"
            height="40"
            width="60"
          />
        </div>
      </div>

      <!-- Total -->
      <div class="detail display-flex align-center">
        <div class="count display-flex flex-col">
          <h1>{{ customersCount.customersCount }}</h1>
          <h2 class="mt-2">Total</h2>
        </div>
        <div class="graph ml-6 display-flex">
          <img
            src="@/assets/icons/dash-graph-img.svg"
            alt="dashboard-graph"
            height="40"
            width="60"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MerchantCustomersCard",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("merchant/dashboard", ["customersCount"]),
  },
  methods: {
    ...mapActions({
      getCustomersCount: "merchant/dashboard/getCustomersCount",
    }),
  },
  async created() {
    await this.getCustomersCount();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.customer-card {
  height: 220px;
  background: #645ff0;
  border: 1px solid #241edf;
  padding: 1.9rem 2rem;
  color: $white-color;
  .header {
    h1 {
      font-weight: 500;
      font-size: 1.125rem;
    }
  }
  .details-container {
    h1 {
      font-weight: bold;
      font-size: 1.5rem;
    }
    h2 {
      font-weight: 500;
      font-size: 1rem;
    }
  }
}
</style>
