<template>
  <div>
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <v-dialog :value="show" persistent :max-width="width">
      <div>
        <!-- title -->
        <div
          class="add-user-title pt-8 pl-6 pr-6 display-flex width-100 align-center justify-space-between"
        >
          {{ title }}
          <v-btn @click="onCancel" icon dark>
            <img src="@/assets/icons/close.svg" />
          </v-btn>
        </div>
        <!-- body -->

        <v-row class="no-gutters mt-4 mr-6">
          <v-col class="col-3">
            <ImageUpload
              placeholder="product"
              label="Product Image"
              :src="imgFile"
              @fileData="getProfileData"
              @removePic="removeProfilePic"
            />
          </v-col>
          <v-col>
            <v-row no-gutters :key="categoryKey">
              <v-col>
                <TextField
                  v-if="isCategoryAdd"
                  label="Category"
                  :required="true"
                  :value="formData.product_category"
                  @change="getFormData($event, 'product_category')"
                  :autofocus="true"
                />
                <ActionSelection
                  v-else
                  label="Select Category"
                  :required="true"
                  :data-set="categoryDataSet"
                  :value="formData.product_category"
                  @change="getFormData($event, 'product_category')"
                  @focus="onFocusCategory"
                />
                <div class="action-select-toggle position-relative">
                  <div
                    class="position-absolute cursor-pointer"
                    @click="isCategoryAdd = false"
                    v-if="isCategoryAdd"
                  >
                    &lt; Select Category
                  </div>
                </div>
              </v-col>
              <v-col class="ml-3">
                <TextField
                  label="Product Code"
                  :value="formData.product_code"
                  :required="true"
                  @change="getFormData($event, 'product_code')"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                ><TextField
                  label="Name"
                  :value="formData.product_name"
                  :required="true"
                  @change="getFormData($event, 'product_name')"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextField
                  v-if="isBrandAdd"
                  label="Brand"
                  :value="formData.product_brand"
                  @change="getFormData($event, 'product_brand')"
                  :autofocus="true"
                /><ActionSelection
                  v-else
                  label="Select Brand"
                  :data-set="brandDataSet"
                  :value="formData.product_brand"
                  @change="getFormData($event, 'product_brand')"
                  @focus="onFocusBrand"
                />
                <div class="action-select-toggle position-relative">
                  <div
                    class="position-absolute cursor-pointer"
                    @click="isBrandAdd = false"
                    v-if="isBrandAdd"
                  >
                    &lt; Select Brand
                  </div>
                </div>
              </v-col>
              <v-col class="ml-3">
                <PriceField
                  label="Price"
                  :value="formData.product_price"
                  :currency="formData.currency"
                  :required="true"
                  @change="getFormData($event, 'product_price')"
                  @currency="getFormData($event, 'currency')"
                  input-type="Number"
                  min="0"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  label="Description"
                  rows="5"
                  :value="formData.product_description"
                  @change="getFormData($event, 'product_description')"
                  :key="descriptionKey"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                ><TextField
                  label="Length (CM)"
                  :value="formData.product_length"
                  @change="getFormData($event, 'product_length')"
                  input-type="Number"
                  min="0"
              /></v-col>
              <v-col class="ml-3">
                <TextField
                  label="Width (CM)"
                  :value="formData.product_width"
                  @change="getFormData($event, 'product_width')"
                  input-type="Number"
                  min="0"
              /></v-col>
              <v-col class="ml-3">
                <TextField
                  label="Height (CM)"
                  :value="formData.product_height"
                  @change="getFormData($event, 'product_height')"
                  input-type="Number"
                  min="0"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="col-6">
                <TextField
                  label="Consumption (Days)"
                  :value="formData.consumption"
                  @change="getFormData($event, 'consumption')"
                  input-type="Number"
                  min="0"
              /></v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- footer -->
        <v-row class="justify-end footer-section pb-5">
          <div class="justify-end">
            <Button
              label="Close"
              type="cancel"
              width="94px"
              @click="onCancel"
            />
            <Button
              class="ml-3"
              label="Save"
              type="save"
              width="94px"
              @click="onSave"
              :disabled="isSubmitDisabled() || isLoading"
              :loading="isLoading"
            />
          </div>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { SERVER_URL } from "../../../../constants";
export default {
  name: "MerchantAddProduct",
  mixins: [validationMixin],
  validations: {
    formData: {
      product_category: { required },
      product_code: { required },
      product_name: { required },
      product_brand: {},
      quantity: {},
      product_price: { required },
      consumption: {},
      product_width: {},
      product_height: {},
      product_length: {},
      product_description: {},
      currency: { required },
    },
  },
  props: ["show", "width", "title", "isEdit", "item"],
  data() {
    return {
      serverUrl: SERVER_URL,
      isNotification: false,
      notificationMessage: "",
      notificationKey: 0,
      isCategoryAdd: false,
      isBrandAdd: false,
      isLoading: false,
      markers: [{ position: { lat: 11.0168, lng: 76.9558 } }],
      formData: {
        product_category: "",
        product_code: "",
        product_name: "",
        product_brand: "",
        quantity: "",
        product_price: "",
        product_description: "",
        consumption: "",
        product_width: "",
        product_height: "",
        product_length: "",
        picture: "",
        currency: "SGD",
        product_image: "",
      },
      categoryDataSet: [],
      brandDataSet: [],
      imgFile: "",
      categoryKey: 0,
      descriptionKey: 10,
    };
  },
  methods: {
    ...mapActions({
      addProduct: "merchant/product/addProduct",
      updateProduct: "merchant/product/updateProduct",
      getProductCategories: "merchant/product/getProductCategories",
      getProductBrands: "merchant/product/getProductBrands",
    }),
    getProfileData(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener("load", () => {
        this.formData.picture = files[0];
        this.imgFile = fr.result;
        this.formData.product_image = files[0].name;
      });
    },
    removeProfilePic() {
      this.imgFile = "";
      this.formData.picture = "";
      this.formData.product_image = "";
    },
    onCancel() {
      this.$emit("close");
    },
    async onSave() {
      try {
        this.isLoading = true;
        const data = new FormData();
        if (this.formData.product_category)
          data.append("product_category", this.formData.product_category);
        if (this.formData.product_code)
          data.append("product_code", this.formData.product_code);
        if (this.formData.product_name)
          data.append("product_name", this.formData.product_name);
        data.append(
          "product_brand",
          this.formData.product_brand ? this.formData.product_brand : ""
        );
        // if (this.formData.quantity)
        //   data.append("quantity", this.formData.quantity);
        if (this.formData.product_price)
          data.append("product_price", this.formData.product_price);
        if (this.formData.currency)
          data.append("currency", this.formData.currency);
        data.append(
          "consumption",
          this.formData.consumption ? this.formData.consumption : ""
        );
        data.append(
          "product_width",
          this.formData.product_width ? this.formData.product_width : ""
        );
        data.append(
          "product_height",
          this.formData.product_height ? this.formData.product_height : ""
        );
        data.append(
          "product_length",
          this.formData.product_length ? this.formData.product_length : ""
        );
        if (this.loggedUser?.company_id)
          data.append("company_id", this.loggedUser?.company_id);
        if (this.formData.picture)
          data.append("picture", this.formData.picture);
        data.append(
          "product_image",
          this.formData.product_image ? this.formData.product_image : null
        );
        data.append(
          "product_description",
          this.formData.product_description
            ? this.formData.product_description
            : ""
        );
        this.isEdit
          ? await this.updateProduct({ id: this.item.id, data: data })
          : await this.addProduct(data);
        this.$emit("save");
        this.isLoading = false;
      } catch (err) {
        this.notificationMessage = err.response.data.message;
        this.isNotification = true;
        this.notificationKey += 1;
        this.isLoading = false;
      }
    },
    setEditFormData() {
      this.formData.product_category = this.item?.product_category;
      this.formData.product_code = this.item?.product_code;
      this.formData.product_name = this.item?.product_name;
      this.formData.product_brand = this.item?.product_brand || null;
      // this.formData.quantity = this.item?.quantity;
      this.formData.currency = this.item?.currency;
      this.formData.product_description =
        this.item?.product_description || null;
      this.descriptionKey += 1;
      this.formData.product_price = this.item?.product_price;
      this.formData.consumption = this.item?.consumption;
      this.formData.product_width = this.item?.product_width || null;
      this.formData.product_height = this.item?.product_height || null;
      this.formData.product_length = this.item?.product_length || null;
      this.formData.product_image = this.item?.product_image;
      this.imgFile = this.item.product_image
        ? `${this.serverUrl}/${this.item.product_image}`
        : "";
    },
    getFormData(value, fieldName) {
      if (
        fieldName === "product_category" &&
        value.action &&
        value.action === "Add Category"
      ) {
        this.formData[fieldName] = "";
        this.isCategoryAdd = true;
        this.categoryKey += 1;
        return;
      }
      if (
        fieldName === "product_brand" &&
        value.action &&
        value.action === "Add Brand"
      ) {
        this.formData[fieldName] = "";
        this.isBrandAdd = true;
        return;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    async onFocusCategory() {
      await this.getProductCategories();
      this.categoryDataSet = [{ action: "Add Category" }, ...this.categories];
      this.categoryKey += 1;
    },
    async onFocusBrand() {
      await this.getProductBrands();
      this.brandDataSet = [{ action: "Add Brand" }, ...this.brands];
      this.categoryKey += 1;
    },
  },
  async created() {
    if (this.show && this.isEdit) {
      this.setEditFormData();
    }
    await this.onFocusCategory();
    await this.onFocusBrand();
  },
  computed: {
    ...mapGetters("merchant/authentication", ["loggedUser"]),
    ...mapGetters("merchant/product", ["brands", "categories"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.v-dialog > * {
  background: #ffffff !important;
  min-height: 550px;
  //   overflow-y: scroll;
}
.action-select-toggle {
  div {
    font-size: 12px;
    color: #ff003f;
    top: -22px;
  }
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.footer-section {
  width: 100%;
}
.locate-in-map {
  color: #808080;
  font-size: 12px;
}
</style>
