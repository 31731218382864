var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.tableKey,staticClass:"warehouse-container pl-14 pr-14"},[_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete Warehouse","cancel":"Cancel","content":"Are you sure you want to delete the selected Warehouse? ","accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}}),(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center"}):_c('div',[(_vm.totalWarehouse === 0)?_c('div',{staticClass:"no-content-container"},[_c('NoContent')],1):_c('v-data-table',{attrs:{"headers":_vm.warehouseHeaders,"items":_vm.allWarehouse,"server-items-length":_vm.totalWarehouse,"options":{ page: _vm.page, itemsPerPage: _vm.limit },"item-key":"id","hide-default-footer":""},on:{"update:page":function($event){return _vm.updatePagination($event)},"update:items-per-page":function($event){return _vm.updatePerPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":"","items-per-page-options":_vm.itemsPerPage,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.warehouse_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.warehouse_name ? item.warehouse_name : "-")+" ")])])]}},{key:"item.contact_person",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.contact_person ? item.contact_person : "-")+" ")])])]}},{key:"item.contact_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.contact_number ? item.contact_number : "-")+" ")])])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.block_number ? item.block_number + ", " : "")+_vm._s(item.floor_number ? item.floor_number + ", " : "")+_vm._s(item.address ? item.address : "-")+" ")]),(item.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.cityortown ? item.cityortown + ", " : "")+_vm._s(item.state ? item.state + ", " : "")+_vm._s(item.country ? item.country : "")+" ")]):_vm._e()]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.phone_number ? item.phone_number : "-")+" ")])]}},{key:"item.fax_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.fax_number ? item.fax_number : "-"))])]}},{key:"item.actions",fn:function(props){return [_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('v-edit-dialog',{attrs:{"return-value":props.item.action},on:{"update:returnValue":function($event){return _vm.$set(props.item, "action", $event)},"update:return-value":function($event){return _vm.$set(props.item, "action", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('InnerMoreOptions',{on:{"editClicked":function($event){return _vm.editClicked(props.item)},"deleteClicked":function($event){return _vm.deleteClicked(props.item)}}})]},proxy:true}],null,true)},[_c('div',{staticClass:"more-options exact-center ml-2 cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])])],1)]}}])})],1),_c('AddWarehouse',{key:_vm.warehouseKey,attrs:{"show":_vm.showAddWarehouse,"width":"691","title":"Edit Warehouse","item":_vm.selectedItem,"isEdit":true},on:{"close":function($event){_vm.showAddWarehouse = false},"saveClicked":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }