<template>
  <div class="orders-inner-table-container width-100">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      hide-default-footer
      v-if="selectedType === 'Customers'"
    >
      <template v-slot:item.customer_name="{ item }">
        <div class="display-flex align-center">
          <Avatar :name="item.customer_name" size="50" radius="12" />
          <div class="name ml-4">
            {{ item.customer_name ? item.customer_name : "-" }}
          </div>
        </div>
      </template>

      <template v-slot:item.mobile_number="{ item }">
        <div class="name">
          {{ item.mobile_number ? item.mobile_number : "-" }}
        </div>
      </template>

      <template v-slot:item.expected_delivery_date="{ item }">
        <div class="name">
          {{
            item.expected_delivery_date
              ? formatDate(item.expected_delivery_date)
              : "-"
          }}
        </div>
      </template>
      <template v-slot:item.delivered_date="{ item }">
        <div class="name">
          {{
            item.assignmentdetails && item.assignmentdetails.delivered_at
              ? formatDate(item.assignmentdetails.delivered_at)
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.billing_address="{ item }">
        <div class="address-1">
          {{
            item.billingaddressdetails.block_number
              ? item.billingaddressdetails.block_number + ","
              : ""
          }}{{
            item.billingaddressdetails.floor_number
              ? item.billingaddressdetails.floor_number + ","
              : "-"
          }}
          {{
            item.billingaddressdetails
              ? item.billingaddressdetails.address
              : "-"
          }}
        </div>
        <div class="address-2" v-if="item.billingaddressdetails">
          {{
            item.billingaddressdetails.cityortown
              ? item.billingaddressdetails.cityortown + ", "
              : ""
          }}{{
            item.billingaddressdetails.country
              ? item.billingaddressdetails.country
              : ""
          }}
        </div>
      </template>
      <template v-slot:item.delivery_address="{ item }">
        <div class="address-1">
          {{
            item.customerdetails.block_number
              ? item.customerdetails.block_number + ","
              : ""
          }}{{
            item.customerdetails.floor_number
              ? item.customerdetails.floor_number + ","
              : "-"
          }}
          {{
            item.customerdetails.address ? item.customerdetails.address : "-"
          }}
        </div>
        <div class="address-2" v-if="item.customerdetails.address">
          {{
            item.customerdetails.cityortown
              ? item.customerdetails.cityortown + ", "
              : ""
          }}{{
            item.customerdetails.country ? item.customerdetails.country : ""
          }}
        </div>
      </template>

      <template v-slot:item.email="{ item }">
        <div class="name">
          {{
            item.customerdetails.email_address
              ? item.customerdetails.email_address
              : "-"
          }}
        </div>
      </template>
      <template v-slot:item.delivered_img="{ item }">
        <div class="name">
          <img
            v-if="
              item.assignmentdetails && item.assignmentdetails.delivered_image
            "
            :src="`${serverUrl}/${item.assignmentdetails.delivered_image}`"
            class="pod-image cursor-pointer"
            alt="pod-img"
            v-viewer="{
              button: true,
              navbar: false,
              title: false,
              toolbar: false,
              tooltip: false,
              movable: false,
              zoomable: false,
              rotatable: false,
              scalable: false,
              transition: true,
              fullscreen: false,
              keyboard: false,
            }"
          />
          <span v-else>-</span>
        </div>
      </template>
    </v-data-table>

    <!-- Orders Timeline -->

    <div
      class="inner-timeline-container width-100 display-flex pl-14 pr-8 pt-6 pb-8"
      v-if="selectedType === 'Orders' && item.logs"
    >
      <div
        class="timeline-item"
        :class="{
          active: item.logs.items['Order placed'],
        }"
      >
        <div class="display-flex align-center">
          <div class="marker"></div>
          <div class="progress-line"></div>
        </div>
        <div class="details mt-4">
          <h1>Order Placed</h1>
          <h2 class="mt-2">
            {{
              item.logs.items["Order placed"]
                ? formatTimelineDate(item.logs.items["Order placed"].created_at)
                : ""
            }}
          </h2>
        </div>
      </div>
      <div
        class="timeline-item"
        :class="{
          active: item.logs.items['Parcel ready for shipping'],
        }"
      >
        <div class="display-flex align-center">
          <div class="marker"></div>
          <div class="progress-line"></div>
        </div>
        <div class="details mt-4">
          <h1>Parcel Ready For Shipping</h1>
          <h2 class="mt-2">
            {{
              item.logs.items["Parcel ready for shipping"]
                ? formatTimelineDate(
                    item.logs.items["Parcel ready for shipping"].created_at
                  )
                : ""
            }}
          </h2>
        </div>
      </div>
      <div
        class="timeline-item"
        v-if="item.logs.items['Parcel picked by logistics']"
        :class="{
          active: item.logs.items['Parcel picked by logistics'],
        }"
      >
        <div class="display-flex align-center">
          <div class="marker"></div>
          <div class="progress-line"></div>
        </div>
        <div class="details mt-4">
          <h1>Parcel Picked by Logistics</h1>
          <h2 class="mt-2" v-if="item.logs.items['Parcel picked by logistics']">
            Driver Name:
            {{
              item.logs.items["Parcel picked by logistics"]
                ? item.logs.items["Parcel picked by logistics"].content
                : ""
            }}
          </h2>
          <h2 class="mt-2">
            {{
              item.logs.items["Parcel picked by logistics"]
                ? formatTimelineDate(
                    item.logs.items["Parcel picked by logistics"].created_at
                  )
                : ""
            }}
          </h2>
        </div>
      </div>

      <div
        class="timeline-item"
        v-if="item.logs.items['Parcel picked by logistics']"
        :class="{
          active: item.logs.items['Parcel at sorting center'],
        }"
      >
        <div class="display-flex align-center">
          <div class="marker"></div>
          <div class="progress-line"></div>
        </div>
        <div class="details mt-4">
          <h1>Parcel at Sorting Center</h1>
          <h2 class="mt-2">
            {{
              item.logs.items["Parcel at sorting center"]
                ? formatTimelineDate(
                    item.logs.items["Parcel at sorting center"].created_at
                  )
                : ""
            }}
          </h2>
        </div>
      </div>

      <div
        class="timeline-item"
        v-if="item.logs.items['Parcel picked by logistics']"
        :class="{
          active: item.logs.items['Parcel picked by logistics to Customer'],
        }"
      >
        <div class="display-flex align-center">
          <div class="marker"></div>
          <div class="progress-line"></div>
        </div>
        <div class="details mt-4">
          <h1>Order Out to Delivery</h1>
          <h2
            class="mt-2"
            v-if="item.logs.items['Parcel picked by logistics to Customer']"
          >
            Driver Name:
            {{
              item.logs.items["Parcel picked by logistics to Customer"]
                ? item.logs.items["Parcel picked by logistics to Customer"]
                    .content
                : ""
            }}
          </h2>
          <h2 class="mt-2">
            {{
              item.logs.items["Parcel picked by logistics to Customer"]
                ? formatTimelineDate(
                    item.logs.items["Parcel picked by logistics to Customer"]
                      .created_at
                  )
                : ""
            }}
          </h2>
        </div>
      </div>

      <div
        class="timeline-item"
        v-if="!item.logs.items['Parcel picked by logistics']"
        :class="{
          active: item.logs.items['Parcel picked by logistics to Customer'],
        }"
      >
        <div class="display-flex align-center">
          <div class="marker"></div>
          <div class="progress-line"></div>
        </div>
        <div class="details mt-4">
          <h1>Parcel picked by logistics to Customer</h1>
          <h2
            class="mt-2"
            v-if="item.logs.items['Parcel picked by logistics to Customer']"
          >
            Driver Name:
            {{
              item.logs.items["Parcel picked by logistics to Customer"]
                ? item.logs.items["Parcel picked by logistics to Customer"]
                    .content
                : "-"
            }}
          </h2>
          <h2 class="mt-2">
            {{
              item.logs.items["Parcel picked by logistics to Customer"]
                ? formatTimelineDate(
                    item.logs.items["Parcel picked by logistics to Customer"]
                      .created_at
                  )
                : ""
            }}
          </h2>
        </div>
      </div>
      <div
        class="timeline-item"
        :class="{
          active: item.logs.items['Order delivered'],
        }"
      >
        <div class="display-flex align-center">
          <div class="marker"></div>
        </div>
        <div class="details mt-4">
          <h1>Order Delivered</h1>
          <h2 class="mt-2">
            {{
              item.logs.items["Order delivered"]
                ? formatTimelineDate(
                    item.logs.items["Order delivered"].created_at
                  )
                : ""
            }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SERVER_URL } from "../../../../constants";

export default {
  name: "MerchantInnerTable",
  components: {},
  props: ["headers", "selectedType", "items", "item"],
  data() {
    return {
      serverUrl: SERVER_URL,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("Do MMM, YYYY");
    },
    formatTimelineDate(date) {
      return moment(date).format("Do MMM,YYYY h:mm a");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.orders-inner-table-container {
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
    background: $white-color !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 18px;
    border-bottom: 1px solid #f6f6f7 !important;
  }
  .pod-image {
    width: 44px;
    height: 57px;
    object-fit: cover;
  }
  ::v-deep
    .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td
    > .v-icon {
    display: none !important;
  }
  ::v-deep .v-data-table > .v-data-footer {
    border: none;
  }

  background: $white-color !important;
  .inner-table-header {
    margin-bottom: 1.5rem;
    .table-title {
      font-weight: 600;
      font-size: 1rem;
      color: $secondary-color;
    }
  }
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }

  //Timeline
  .inner-timeline-container {
    background: rgba(51, 51, 51, 0.02);
    .blur {
      opacity: 0.5 !important;
    }
    .active {
      .marker {
        background: $primary-color !important;
        opacity: 1;
      }
    }
    .timeline-item {
      min-width: 200px;
      .marker {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: grey;
      }
      .progress-line {
        width: 100%;
        height: 1px;
        background: #e3e3e3;
      }
      .details {
        padding-right: 1.5rem;
        h1 {
          font-size: 1rem;
        }
        h2 {
          color: #999da0;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
