<template>
  <div>
    <CommonToolbar
      title="Orders"
      :primaryButtonLabel="
        loggedUserPermission.edit_orders &&
          loggedUserPermission.view_orders &&
          'Add Order'
      "
      :secondaryButtonLabel="loggedUserPermission.view_orders && 'Export'"
      :isSearch="loggedUserPermission.view_orders"
      @search="onSearch"
      @primaryButtonClick="addClick"
      @secondaryButtonClick="exportClick"
    >
      <!-- Filter Slot -->
      <v-edit-dialog ref="orderFilterModal">
        <v-btn
          v-if="loggedUserPermission.view_orders"
          class="mr-4 position-relative"
          icon
          dark
        >
          <img src="@/assets/icons/filter.svg" />
          <div v-if="isOrderFilter" class="filter-marker position-absolute"></div>
        </v-btn>
        <template v-slot:input>
          <OrderFilter
            @onClose="resetFilterClicked"
            @onFilter="filterClicked"
            :key="filterModalKey"
          />
        </template>
      </v-edit-dialog>
    </CommonToolbar>

    <SubHeader :items="items" />
    <div class="order-table-container">
      <TableSection
        v-if="loggedUserPermission.view_orders"
        :key="tableKey"
        @saveClicked="saveClicked"
      />
      <NoAccess v-else class="no-content-container" />
    </div>
    <AddOrder
      :show="showAddOrder"
      :width="920"
      :key="modalKey"
      @close="closeAddOrder"
      title="Add Order"
      @saveClicked="saveClicked"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MERCHANT_SUB_NAV_ITEMS } from "../../../../constants";
import TableSection from "@/components/Merchant/Orders/TableSection";
import AddOrder from "@/components/Merchant/Orders/AddOrder.vue";
import OrderFilter from "@/components/Merchant/Orders/OrderFilter.vue";

export default {
  name: "MerchantOrders",
  data() {
    return {
      show: false,
      items: MERCHANT_SUB_NAV_ITEMS,
      showAddOrder: false,
      tableKey: 10,
      modalKey: 0,
      isButtonLoading: false,
      filterModalKey: 100,
    };
  },
  components: { TableSection, AddOrder, OrderFilter },
  methods: {
    ...mapActions({
      setSearchQuery: "merchant/orders/setSearchQuery",
      exportOrders: "merchant/orders/exportOrders",
      getWarehouses: "merchant/warehouse/getWarehouses",
      getMerchantTabCount: "merchant/count/getMerchantTabCount",
      setPage: "merchant/orders/setPage",
      setFilterValues: "merchant/orders/setFilterValues",
    }),
    async addClick() {
      this.showAddOrder = true;
    },
    async exportClick() {
      await this.exportOrders();
    },
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    saveClicked() {
      this.showAddOrder = false;
      this.modalKey += 1;
      this.tableKey += 1;
    },
    closeAddOrder() {
      this.showAddOrder = false;
      this.modalKey += 1;
    },
    filterClicked() {
      this.setPage(1);
      this.$refs["orderFilterModal"].isActive = false;
      this.tableKey += 1;
    },
    resetFilterClicked() {
      this.$refs["orderFilterModal"].isActive = false;
      this.filterModalKey += 1;
      this.tableKey += 1;
    },
  },
  watch: {
    totalOrders() {
      this.items[0].count = this.totalOrders;
    },
  },
  computed: {
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
    ...mapGetters("merchant/orders", ["totalOrders", "isOrderFilter"]),
    ...mapGetters("merchant/count", ["count"]),
  },
  async created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setFilterValues(null);
    await this.getWarehouses();
    await this.getMerchantTabCount();
    this.items[0].count = this.count.orderCount;
    this.items[1].count = this.count.productCount;
    this.items[2].count = this.count.customerCount;
    this.items[3].count = this.count.warehouseCount;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.order-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
}
.filter-marker {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: $primary-color;
  top: -2px;
  right: -3px;
}
</style>
