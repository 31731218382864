<template>
  <div>
    <CommonToolbar
      title="Customers"
      :secondaryButtonLabel="loggedUserPermission.view_customers && 'Export'"
      :isSearch="loggedUserPermission.view_customers"
      @search="onSearch"
      @secondaryButtonClick="exportClick"
      @filterClicked="filterClicked"
      @resetClicked="resetClicked"
      :isFilter="fromDate && toDate"
      :dateFilterType="dateFilterType"
      :showDateFilter="loggedUserPermission.view_customers"
    />
    <SubHeader :items="items" />
    <TableSection v-if="loggedUserPermission.view_customers" :key="tableKey" />
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MERCHANT_SUB_NAV_ITEMS } from "../../../../constants";
import TableSection from "./TableSection.vue";
export default {
  name: "MerchantCustomers",
  components: {
    TableSection,
  },
  data() {
    return {
      tableKey: 0,
      items: MERCHANT_SUB_NAV_ITEMS,
      dateFilterType: [
        {
          name: "Last Ordered Date",
          value: "last_order",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("merchant/customers", ["fromDate", "toDate", "totalCustomers"]),
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
    ...mapGetters("merchant/count", ["count"]),
  },
  watch: {
    totalCustomers() {
      this.items[2].count = this.totalCustomers;
    },
  },
  methods: {
    ...mapActions({
      setSearchQuery: "merchant/customers/setSearchQuery",
      exportCustomers: "merchant/customers/exportCustomers",
      setDateRange: "merchant/customers/setDateRange",
      getMerchantTabCount: "merchant/count/getMerchantTabCount",
      setPage: "merchant/customers/setPage",
    }),
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    filterClicked(value) {
      this.setPage(1);
      this.setDateRange(value);
      this.tableKey += 1;
    },
    resetClicked() {
      this.setDateRange({});
      this.tableKey += 1;
    },
    async exportClick() {
      await this.exportCustomers();
    },
  },
  async created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setDateRange({});
    this.tableKey += 1;
    await this.getMerchantTabCount();
    this.items[0].count = this.count.orderCount;
    this.items[1].count = this.count.productCount;
    this.items[2].count = this.count.customerCount;
    this.items[3].count = this.count.warehouseCount;
  },
};
</script>

<style lang="scss" scoped></style>
