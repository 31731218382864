<template>
  <div class="merchant-table-container pl-7 pr-7 width-100">
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="totalCustomers === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="customerHeaders"
        :items="customers"
        :expanded.sync="expanded"
        item-key="id"
        hide-default-footer
        show-expand
        :options="{ page: page, itemsPerPage: limit }"
        :server-items-length="totalCustomers"
        @update:page="updatePagination($event)"
        @update:items-per-page="updatePerPage($event)"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template>
        <template v-slot:item.user_first_name="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.user_img"
              :name="item.user_first_name"
              :url="`${serverUrl}/${item.user_img}`"
              size="50"
              radius="12"
            />
            <Avatar v-else :name="item.user_first_name" size="50" radius="12" />
            <div class="name ml-4">
              {{ item.user_first_name ? item.user_first_name : "-" }}
              {{ item.user_last_name }}
            </div>
          </div>
        </template>

        <template v-slot:item.contact_number="{ item }">
          <div class="name">
            {{ item.contact_number ? item.contact_number : "-" }}
          </div>
        </template>

        <template v-slot:item.address="{ item }">
          <div class="address-1">
            {{ item.block_number ? item.block_number + ", " : ""
            }}{{ item.floor_number ? item.floor_number + ", " : ""
            }}{{ item.address ? item.address : "-" }}
          </div>
          <div class="address-2" v-if="item.address">
            {{ item.cityortown ? item.cityortown + ", " : ""
            }}{{ item.state ? item.state + ", " : ""
            }}{{ item.country ? item.country : "" }}
          </div>
        </template>

        <template v-slot:item.email_address="{ item }">
          <div class="name">
            {{ item.email_address ? item.email_address : "-" }}
          </div>
        </template>

        <template v-slot:item.last_login="{ item }">
          <div class="name">
            <div>{{ item.last_login ? formatDate(item.last_login) : "-" }}</div>
            <div class="time">
              {{ item.last_login ? formatTime(item.last_login) : "" }}
            </div>
          </div>
        </template>

        <template v-slot:item.last_order="{ item }">
          <div class="name">
            <div>
              {{ item.lastorderdate ? formatDate(item.lastorderdate) : "-" }}
            </div>
            <div class="time">
              {{ item.lastorderdate ? formatTime(item.lastorderdate) : "" }}
            </div>
          </div>
        </template>

        <template v-slot:item.created_at="{ item }">
          <div class="name">
            <div>
              {{ item.created_at ? formatDate(item.created_at) : "-" }}
            </div>
            <div class="time">
              {{ item.created_at ? formatTime(item.created_at) : "" }}
            </div>
          </div>
        </template>

        <template v-slot:item.actions="props">
          <div class="action-buttons display-flex align-center">
            <ActionButton
              label="Orders"
              :count="props.item.ordercount"
              icon="orders"
              width="124px"
              class="mr-4"
              @click="expandClicked(props, 'Orders')"
              :active="props.item.orderSelected"
            />
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="inner-table-td disply-flex" :colspan="headers.length">
            <InnerTable
              :item="item"
              :selectedType="item.type"
              :headers="item.innerHeaders"
              :items="item.innerItems"
              :keyValue="innerTableKey"
              :totalItem="item.totalItem"
              :page="item.page"
              :isFilter="item.isFilter"
              :searchValue="item.searchValue"
              @updatePagination="updateInnerTablePage"
              @onSearch="onSearch"
              @filterClicked="filterClicked"
            />
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import InnerTable from "@/components/Merchant/Customers/InnerTable";
import { SERVER_URL, ITEMS_PER_PAGE } from "../../../../constants";
export default {
  name: "TableSection",
  components: {
    InnerTable,
  },
  data() {
    return {
      isLoading: false,
      innerTableKey: 20,
      expanded: [],
      serverUrl: SERVER_URL,
      itemsPerPage: ITEMS_PER_PAGE,
      isTableLoading: false,
      customerHeaders: [
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "user_first_name",
          width: "220px",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "contact_number",
          width: "180px",
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "address",
          width: "260px",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email_address",
          width: "200px",
        },
        // {
        //   text: "Last Login Date",
        //   align: "start",
        //   sortable: false,
        //   value: "last_login",
        //   width: "200px",
        // },
        {
          text: "Last Order Date",
          align: "start",
          sortable: false,
          value: "last_order",
          width: "200px",
        },
        // {
        //   text: "Created Date",
        //   align: "start",
        //   sortable: false,
        //   value: "created_at",
        //   width: "200px",
        // },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions",
          width: "200px",
        },
        { text: "", value: "data-table-expand" },
      ],
      orderHeaders: [
        {
          text: "Tracking Id",
          align: "start",
          sortable: false,
          value: "tracking_id",
          width: "150px",
        },
        {
          text: "Order No.",
          align: "start",
          sortable: false,
          value: "order_no",
          width: "150px",
        },
        {
          text: "Logistics",
          align: "start",
          sortable: false,
          value: "logistics",
          width: "200px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
          width: "200px",
        },
        {
          text: "Product Category",
          align: "start",
          sortable: false,
          value: "product_category",
          width: "200px",
        },
        {
          text: "Product Code",
          align: "start",
          sortable: false,
          value: "product_code",
          width: "150px",
        },
        {
          text: "Product Name",
          align: "start",
          sortable: false,
          value: "product_name",
          width: "300px",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
          width: "280px",
        },
        {
          text: "Qty",
          align: "start",
          sortable: false,
          value: "product_quantity",
          width: "30px",
        },
        {
          text: "UOM",
          align: "start",
          sortable: false,
          value: "uom",
          width: "150px",
        },
        {
          text: "Ordered Date",
          align: "start",
          sortable: false,
          value: "created_at",
          width: "150px",
        },
        {
          text: "Exp del date",
          align: "start",
          sortable: false,
          value: "expected_delivery_date",
          width: "150px",
        },
        {
          text: "Price",
          align: "start",
          sortable: false,
          value: "price",
          width: "120px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("merchant/customers", [
      "totalCustomers",
      "customers",
      "page",
      "limit",
    ]),
    ...mapGetters("merchant/customerOrders", [
      "orders",
      "totalOrders",
      "ordersPage",
      "ordersFromDate",
      "ordersToDate",
    ]),
  },
  methods: {
    ...mapActions({
      getCustomers: "merchant/customers/getCustomers",
      setPage: "merchant/customers/setPage",
      setLimit: "merchant/customers/setLimit",
      getOrders: "merchant/customerOrders/getOrders",
      setOrdersSearchQuery: "merchant/customerOrders/setSearchQuery",
      setOrdersPage: "merchant/customerOrders/setPage",
      setOrdersDateRange: "merchant/customerOrders/setDateRange",
    }),
    async updatePagination(value) {
      this.expanded = []
      this.setPage(value);
      await this.fetchData();
    },
    async updatePerPage(value) {
      this.expanded = []
      this.setLimit(value);
      await this.fetchData();
    },
    async fetchOrdersData(item) {
      try {
        this.isTableLoading = true;
        await this.getOrders(item);
        this.isTableLoading = false;
      } catch (err) {
        console.log(err);
        this.isTableLoading = false;
      }
    },
    async filterClicked(selectedItem) {
      this.customers.map(async (item) => {
        if (item.id === selectedItem?.customer?.id) {
          switch (selectedItem.type) {
            case "Orders":
              this.setOrdersDateRange(selectedItem.value);
              this.setOrdersPage(1);
              await this.fetchOrdersData(item);
              item.innerItems = this.orders;
              item.page = this.ordersPage;
              item.totalItem = this.totalOrders;
              item.isFilter = this.ordersFromDate && this.ordersToDate;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async onSearch(selectedItem) {
      this.customers.map(async (item) => {
        if (item.id === selectedItem?.customer?.id) {
          switch (selectedItem.type) {
            case "Orders":
              this.setOrdersSearchQuery(selectedItem.value);
              this.setOrdersPage(1);
              await this.fetchOrdersData(item);
              item.innerItems = this.orders;
              item.page = this.ordersPage;
              item.totalItem = this.totalOrders;
              item.isFilter = this.ordersFromDate && this.ordersToDate;
              item.searchValue = selectedItem.value;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async updateInnerTablePage(selectedItem) {
      this.customers.map(async (item) => {
        if (item.id === selectedItem?.customer?.id) {
          switch (selectedItem.type) {
            case "Orders":
              this.setOrdersSearchQuery("");
              this.setOrdersPage(selectedItem.page);
              await this.fetchOrdersData(item);
              item.innerItems = this.orders;
              item.page = this.ordersPage;
              item.totalItem = this.totalOrders;
              item.searchValue = "";
              this.isTableLoading = false;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async expandClicked(slotData, type) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1) {
        this.expanded.splice(indexExpanded, 1);
        this.customers.map((item) => {
          if (item.id === slotData.item.id) {
            item.orderSelected = false;
          }
        });
      } else {
        this.expanded = [];
        this.expanded.push(slotData.item);
        this.customers.map(async (item) => {
          if (item.id === slotData.item.id) {
            item.orderSelected = true;
            item.innerHeaders = this.orderHeaders;
            this.setOrdersSearchQuery("");
            this.setOrdersDateRange({});
            await this.fetchOrdersData(item);
            item.innerItems = this.orders;
            item.page = this.ordersPage;
            item.totalItem = this.totalOrders;
            item.type = type;
            item.searchValue = "";
            this.innerTableKey += 1;
          } else {
            item.orderSelected = false;
          }
        });
      }
    },
    async fetchData() {
      this.isLoading = true;
      await this.getCustomers();
      this.isLoading = false;
    },
    formatDate(date) {
      return moment(date).format("Do MMM,YYYY");
    },
    formatTime(date) {
      return moment(date).format("h:mm a");
    },
  },
  async created() {
    this.expanded = [];
    this.setOrdersPage(1);
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td
  > .v-icon {
  display: none !important;
}
::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.inner-table-td {
  background: $white-color !important;
}

.merchant-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .time {
    color: #c5c5d0;
  }
}
</style>
