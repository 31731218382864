<template>
  <div :key="tableKey" class="user-table-container pl-7 pr-7 width-100">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete User`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected User? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <Spinner v-if="isLoading" class="spinner-class exact-center" />
    <div v-else>
      <div v-if="totalUsers === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="userHeaders"
        :items="allUsers"
        item-key="id"
        @update:page="updatePagination($event)"
        :server-items-length="totalUsers"
        :options="{ page: page, itemsPerPage: limit }"
        @update:items-per-page="updatePerPage($event)"
        hide-default-footer
        show-expand
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template>
        <template v-slot:item.user_name="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.user_img"
              :url="`${serverUrl}/${item.user_img}`"
              :name="item.user_name"
              size="50"
              radius="12"
            />
            <Avatar v-else :name="item.user_name" size="50" radius="12" />
            <div class="name ml-4">
              {{ item.user_name ? item.user_name : "-" }}
            </div>
          </div>
        </template>
        <template v-slot:item.resetPassword="{ item }">
          <div>
            <img
              class="cursor-pointer"
              @click="onClickReset(item)"
              v-if="!item.isResetSelected"
              src="@/assets/icons/reset.svg"
            />
            <img
              class="cursor-pointer"
              @click="onClickReset(item)"
              v-else
              src="@/assets/icons/reset-active.svg"
            />
          </div>
        </template>
        <template v-slot:item.phone_number="{ item }">
          <div class="name">
            {{ item.phone_number ? item.phone_number : "-" }}
          </div>
        </template>
        <template v-slot:item.last_login="{ item }">
          <div>
            <div class="name">
              {{ item.last_login ? formatDate(item.last_login) : "-" }}
              <div class="time">
                {{ item.last_login ? formatTime(item.last_login) : "" }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item.last_session="{ item }">
          <div>
            <div class="name">
              {{ item.last_session ? formatDate(item.last_session) : "-" }}
              <div class="time">
                {{ item.last_session ? formatTime(item.last_session) : "" }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>
            <div class="name">
              {{ formatDate(item.created_at) }}
              <div class="time">{{ formatTime(item.created_at) }}</div>
            </div>
          </div>
        </template>
        <template v-slot:item.role="{ item }">
          <div
            class="role exact-center normal-user"
            :class="{
              'super-user': item.role.name === 'Super User',
            }"
          >
            {{ item.role && item.role.name ? item.role.name : "-" }}
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <div
            class="role exact-center inactive-user"
            :class="{
              'active-user': !item.archived,
            }"
          >
            {{ item.archived ? "Inactive" : "Active" }}
          </div>
        </template>
        <template v-slot:item.actions="props">
          <div class="action-buttons display-flex align-center">
            <v-edit-dialog :return-value.sync="props.item.action">
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @editClicked="editClicked(props.item)"
                  @deleteClicked="deleteClicked(props.item)"
                  :isActiveInactive="true"
                  @activeToggle="activeClicked(props.item)"
                  :active="!props.item.archived"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>
      </v-data-table>
    </div>
    <AddUser
      width="928px"
      :show="showUser"
      @close="showUser = false"
      title="Edit User"
      :item="selectedItem"
      :key="addUserKey"
      :isEdit="true"
      @saveClicked="saveClicked"
    />
  </div>
</template>

<script>
import moment from "moment";
import lodash from "lodash";
import { mapGetters, mapActions } from "vuex";
import AddUser from "@/components/Merchant/Users/AddUser";
import { SERVER_URL, ITEMS_PER_PAGE } from "../../../../constants";
export default {
  name: "MerchantUserTable",
  components: {
    AddUser,
  },
  data() {
    return {
      itemsPerPage: ITEMS_PER_PAGE,
      serverUrl: SERVER_URL,
      isLoading: false,
      showUser: false,
      tableKey: 0,
      selectedItem: {},
      addUserKey: 10,
      deleteDialog: false,
      userHeaders: [
        {
          text: "User Name",
          align: "start",
          sortable: false,
          value: "user_name",
          width: "250px",
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "role",
          width: "200px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
          width: "200px",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "phone_number",
          width: "200px",
        },
        // {
        //   text: "Reset Password",
        //   align: "start",
        //   sortable: false,
        //   value: "resetPassword",
        //   width: "180px",
        // },
        {
          text: "Last Login Date",
          align: "start",
          sortable: false,
          value: "last_login",
          width: "200px",
        },
        // {
        //   text: "Last Session",
        //   align: "start",
        //   sortable: false,
        //   value: "last_session",
        //   width: "200px",
        // },
        {
          text: "Created Date",
          align: "start",
          sortable: false,
          value: "created_at",
          width: "200px",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
          width: "180px",
        },
      ],
      allUsers: [],
    };
  },
  computed: {
    ...mapGetters("merchant/users", ["users", "totalUsers", "page", "limit"]),
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      getUsers: "merchant/users/getUsers",
      setPage: "merchant/users/setPage",
      setLimit: "merchant/users/setLimit",
      deleteUser: "merchant/users/deleteUser",
      setActiveUser: "merchant/users/setActiveUser",
    }),
    editClicked(item) {
      this.selectedItem = item;
      this.showUser = true;
      this.addUserKey += 1;
    },
    formatDate(date) {
      return moment(date).format("DD MMM, YYYY");
    },
    formatTime(date) {
      return moment(date).format("hh:mm a");
    },
    onClickReset(item) {
      this.users.map((obj) => {
        if (obj.id === item.id) obj.isResetSelected = !obj.isResetSelected;
        else obj.isResetSelected = false;
      });
    },
    async getAllUsers() {
      try {
        await this.getUsers();
        this.allUsers = lodash.cloneDeep(this.users);
      } catch (err) {
        console.log(err);
      }
    },
    async updatePagination(value) {
      this.isLoading = true;
      this.setPage(value);
      this.getAllUsers();
      this.isLoading = false;
    },
    async updatePerPage(value) {
      this.isLoading = true;
      this.setLimit(value);
      this.getAllUsers();
      this.isLoading = false;
    },
    saveClicked() {
      this.showUser = false;
      this.selectedItem = {};
      this.$emit("refresh");
    },
    deleteClicked(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },
    async onDeleteConfirm() {
      try {
        await this.deleteUser(this.selectedItem);
        await this.getAllUsers();
        this.selectedItem = {};
        this.deleteDialog = false;
      } catch (error) {
        console.log(error);
        this.deleteDialog = false;
      }
    },
    async activeClicked(item) {
      try {
        item.archived
          ? await this.setActiveUser(item)
          : await this.deleteUser(item);
        this.$emit("refresh");
      } catch (error) {
        console.log(error);
      }
    },
    onDeleteCancel() {
      this.selectedItem = {};
      this.deleteDialog = false;
    },
  },
  async created() {
    this.isLoading = true;
    if (!this.loggedUserPermission.edit_users) {
      this.userHeaders.splice(this.userHeaders.length - 1, 1);
    }
    await this.getAllUsers();
    this.isLoading = false;
    this.tableKey += 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td
  > .v-icon {
  display: none !important;
}
::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.user-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .time {
    color: #b5b5c3;
  }
  .role {
    width: 128px;
    height: 36px;
    border-radius: 31px;
    font-weight: 500;
    font-size: 12px;
  }
  .super-user {
    background: #ffede3 !important;
    color: #e87d1b !important;
  }
  .normal-user {
    background: #eeefff;
    color: #1b25c6;
  }
}
</style>
