<template>
  <div>
    <div v-if="loggedUserPermission.management_dashboard" class="p-4">
      <v-row class="no-gutters">
        <v-col class="col-8">
          <OrderSummary />
          <v-row>
            <v-col class="col-6">
              <CustomersCard />
              <OrdersCard @addOrder="addOrder" />
            </v-col>
            <v-col class="col-6">
              <OrdersGraphCard />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <UsersCard
                :loading="isLoading"
                :key="userKey"
                :count="resourceCounts.usersCount"
              />
            </v-col>
            <v-col class="col-6">
              <WarehouseCard
                :loading="isLoading"
                :key="warehouseKey"
                :count="resourceCounts.warehouseCount"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-4">
          <TrackingCard />
        </v-col>
      </v-row>
      <AddOrder
        :show="showAddOrder"
        title="Add Order"
        :width="920"
        @close="showAddOrder = false"
        @saveClicked="saveClicked"
      />
    </div>
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TrackingCard from "./TrackingCard.vue";
import OrderSummary from "./OrderSummary.vue";
import CustomersCard from "./CustomersCard.vue";
import OrdersCard from "./OrdersCard.vue";
import UsersCard from "./UsersCard.vue";
import WarehouseCard from "./WarehouseCard.vue";
import OrdersGraphCard from "./OrdersGraphCard.vue";
import AddOrder from "../Orders/AddOrder";

export default {
  name: "MerchantDashboard",
  data() {
    return {
      showAddOrder: false,
      isLoading: false,
      warehouseKey: 0,
      userKey: 10,
    };
  },
  computed: {
    ...mapGetters("merchant/dashboard", ["resourceCounts"]),
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
  },
  components: {
    TrackingCard,
    OrderSummary,
    CustomersCard,
    OrdersGraphCard,
    OrdersCard,
    UsersCard,
    WarehouseCard,
    AddOrder,
  },
  methods: {
    ...mapActions({
      getResourceCounts: "merchant/dashboard/getResourceCounts",
    }),
    addOrder() {
      this.showAddOrder = true;
    },
    saveClicked(){
      this.showAddOrder = false;
      this.$router.push("/merchant/orders")
    },
    async fetchAllCounts() {
      this.isLoading = true;
      await this.getResourceCounts();
      this.warehouseKey += 1;
      this.isLoading = false;
    },
  },
  async created() {
    await this.fetchAllCounts();
  },
};
</script>
