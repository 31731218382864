<template>
  <div
    class="header-outer width-100 justify-space-between align-center pl-16 pr-16"
  >
    <div class="logo-container">
      <img src="@/assets/logo.svg" alt="logo" />
    </div>
    <div :key="menuKey" class="menu-container display-flex align-center">
      <div v-for="item in items" :key="item.title">
        <div
          v-if="item.show"
          @click="selectItem(item)"
          class="menu-item position-relative cursor-pointer"
          :class="{ 'menu-item-active': item.isActive }"
        >
          <h1>{{ item.title }}</h1>
          <div class="active-menu position-absolute"></div>
        </div>
      </div>
    </div>
    <div class="user-container display-flex align-center position-relative">
      <div class="details">
        <h1 v-if="loggedUser.user_name">{{ loggedUser.user_name }}</h1>
        <h2 v-if="loggedUser.role">{{ loggedUser.role.name }}</h2>
        <h3 v-if="loggedUser.company">{{ loggedUser.company.company_name }}</h3>
      </div>
      <Avatar
        @click="show = !show"
        v-if="loggedUser.user_img"
        :name="loggedUser.user_name"
        :url="`${serverUrl}/${loggedUser.user_img}`"
        size="50"
        radius="12"
        :clickable="true"
      />
      <Avatar
        v-else
        @click="show = !show"
        :name="loggedUser.user_name"
        size="50"
        radius="12"
        :clickable="true"
      />
      <div
        v-click-outside="onBlur"
        v-if="show"
        class="position-absolute outer-layout"
      >
        <v-list>
          <v-list-item @click="onClickFeedBack" class="option-list">
            <div>
              <img src="@/assets/icons/feedback.svg" /><span class="ml-2">
                Feedback</span
              >
            </div>
          </v-list-item>
          <v-list-item @click="onClickSettings" class="option-list">
            <div>
              <img src="@/assets/icons/settings.svg" /><span class="ml-2">
                Settings</span
              >
            </div>
          </v-list-item>
          <v-list-item class="option-list" @click="onClickLogout">
            <div>
              <img src="@/assets/icons/logout.svg" /><span class="ml-3"
                >Logout</span
              >
            </div>
          </v-list-item>
        </v-list>
        <FeedbackForm
          :width="750"
          :show="showFeedback"
          @close="showFeedback = false"
          title="Feedback"
          :key="editKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FeedbackForm from "./FeedbackForm.vue";
import { MERCHANT_NAV_ITEMS, SERVER_URL } from "../../../../constants";
export default {
  name: "MerchantHeader",
  components: { FeedbackForm },
  props: {},
  data() {
    return {
      items: [],
      serverUrl: SERVER_URL,
      menuKey: 0,
      isSettings: true,
      show: false,
      editKey: 10,
      showFeedback: false,
    };
  },
  methods: {
    ...mapActions({
      logout: "merchant/authentication/logout",
    }),
    selectItem(item) {
      this.items.map((menu) => {
        if (menu.route === item.route) {
          menu.isActive = true;
          this.$router.push(menu.route).catch(() => {});
        } else {
          menu.isActive = false;
        }
      });
    },
    onClickFeedBack() {
      this.showFeedback = true;
    },
    onClickLogout() {
      this.isSettings = false;
      this.show = false;
      this.logout();
      this.$router.push("/auth/login/admin").catch(() => {});
    },
    onClickSettings() {
      this.isSettings = true;
      this.$router.push("/merchant/settings").catch(() => {});
      this.show = false;
    },
    setNavItems() {
      this.items = MERCHANT_NAV_ITEMS;
      this.items.map((item) => {
        switch (item.title) {
          case "Dashboard":
            item.show = this.loggedUserPermission.management_dashboard;
            break;
          case "Users":
            item.show = this.loggedUserPermission.view_users;
            break;
          default:
            item.show = true;
        }
      });
      this.setMerchantRoute();
    },
    setMerchantRoute() {
      this.items.map((menu) => {
        if (
          this.$route.path.split("/")[1] === "merchant" &&
          this.$route.path.split("/")[2] === "dashboard" &&
          menu.title === "Dashboard"
        ) {
          menu.isActive = true;
        } else if (
          this.$route.path.split("/")[1] === "merchant" &&
          (this.$route.path.split("/")[2] === "orders" ||
            this.$route.path.split("/")[2] === "products" ||
            this.$route.path.split("/")[2] === "orders" ||
            this.$route.path.split("/")[2] === "customers" ||
            this.$route.path.split("/")[2] === "warehouse") &&
          menu.title === "Merchant"
        ) {
          menu.isActive = true;
        } else if (
          this.$route.path.split("/")[1] === "merchant" &&
          this.$route.path.split("/")[2] === "users" &&
          menu.title === "Users"
        ) {
          menu.isActive = true;
        } else {
          menu.isActive = false;
        }
      });
    },
    onBlur() {
      if (!this.showFeedback) this.show = false;
    },
  },
  computed: {
    ...mapGetters("merchant/authentication", [
      "loggedUser",
      "loggedUserPermission",
    ]),
  },
  async mounted() {
    this.setNavItems();
  },
  watch: {
    $route() {
      this.setNavItems();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.header-outer {
  background: $white-color;
  height: 6rem;
  .user-container {
    text-align: right;
    .details {
      margin-right: 0.938rem;
      h1 {
        color: $secondary-color;
        font-weight: 600;
        font-size: 0.875rem;
        margin-bottom: 0.375rem;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h2 {
        color: #999da0;
        font-weight: 500;
        font-size: 0.813rem;
        width: 150px;
        word-wrap: break-word;
        margin-bottom: 0.375rem;
      }
      h3 {
        color: $primary-color;
        font-weight: 500;
        font-size: 10px;
        width: 150px;
        word-wrap: break-word;
      }
    }
  }
  .menu-container {
    height: 6rem;
    .menu-item {
      margin: 0 1.875rem;
      h1 {
        color: #999da0;
        font-size: 1rem;
      }
      .active-menu {
        display: none;
      }
    }

    .menu-item-active {
      h1 {
        color: $primary-color;
      }
      .active-menu {
        display: block;
        height: 0.188rem;
        width: 100%;
        bottom: -2.415rem;
        background: $primary-color;
        border-radius: 0.375rem 0.375rem 0px 0px;
      }
    }
  }
}
.outer-layout {
  width: 148px;
  height: 110px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  font-weight: normal;
  font-size: 14px;
  color: #4a5571;
  top: 50px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}
.selected-option {
  background: #e8eaf6;
  border-radius: 4px;
}
.option-list {
  margin: 0 6px;
}
</style>
