<template>
  <div class="merchant-inner-table-container width-100">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Order`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Order ? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <div
      class="inner-table-header display-flex justify-space-between align-center"
    >
      <div class="display-flex align-center">
        <img
          v-if="selectedType === 'Orders'"
          src="@/assets/icons/orders.svg"
          class="mb-1"
        />
        <div class="table-title ml-2">{{ selectedType }}</div>
        <SearchInput
          :value="searchValue"
          :autofocus="true"
          class="ml-4"
          @change="onSearch"
        />
        <DateFilter
          class="ml-4"
          :isFilter="isFilter"
          @filterClicked="filterClicked"
          @resetClicked="resetClicked"
          :dateFilterType="dateFilterType"
          :keyValue="keyValue"
        />
      </div>
    </div>
    <v-data-table
      :key="keyValue"
      :headers="headers"
      :items="items"
      item-key="id"
      hide-default-footer
      show-expand
      :server-items-length="totalItem"
      @update:page="updatePagination($event)"
      :options="{ page: page, itemsPerPage: 5 }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          show-first-last-page
        />
      </template>
      <template v-slot:item.tracking_id="{ item }">
        <div class="name">{{ item.tracking_id ? item.tracking_id : "-" }}</div>
      </template>

      <template v-slot:item.order_no="{ item }">
        <div class="name">
          {{ item.orderdetail.orderId ? item.orderdetail.orderId : "-" }}
        </div>
      </template>

      <template v-slot:item.product_category="{ item }">
        <div class="name">
          {{ item.productdetails ? item.productdetails.product_category : "-" }}
        </div>
      </template>

      <template v-slot:item.product_code="{ item }">
        <div class="name">
          {{ item.productdetails ? item.productdetails.product_code : "-" }}
        </div>
      </template>

      <template v-slot:item.product_name="{ item }">
        <div class="display-flex align-center">
          <Avatar
            v-if="item.productdetails.product_image"
            :url="`${serverUrl}/${item.productdetails.product_image}`"
            :name="item.productdetails.product_name"
            size="50"
            radius="12"
          />
          <Avatar
            v-else
            :name="item.productdetails.product_name"
            size="50"
            radius="12"
          />
          <div class="ml-2 name">
            {{ item.productdetails ? item.productdetails.product_name : "-" }}
          </div>
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <v-tooltip bottom max-width="500px">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="description ellipsis">
              {{
                item.productdetails
                  ? item.productdetails.product_description
                  : "-"
              }}
            </div>
          </template>
          <div>
            {{
              item.productdetails
                ? item.productdetails.product_description
                : "-"
            }}
          </div>
        </v-tooltip>
      </template>

      <template v-slot:item.product_quantity="{ item }">
        <div class="name">
          {{ item.product_quantity ? item.product_quantity : "-" }}
        </div>
      </template>

      <template v-slot:item.uom="{ item }">
        <div class="name">{{ item.uom ? item.uom : "-" }}</div>
      </template>

      <template v-slot:item.created_at="{ item }">
        <div class="name">
          {{ item.created_at ? formatProductDate(item.created_at) : "-" }}
        </div>
      </template>

      <template v-slot:item.expected_delivery_date="{ item }">
        <div class="name">
          {{
            item.expected_delivery_date
              ? formatProductDate(item.expected_delivery_date)
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.price="{ item }">
        <div class="price">
          {{
            item.productdetails.currency
              ? currencyArray[item.productdetails.currency]
              : ""
          }}
          {{
            item.productdetails
              ? formatPrice(
                  item.productdetails.product_price,
                  item.product_quantity
                )
              : "-"
          }}
        </div>
      </template>
      <template v-slot:item.logistics="{ item }">
        <div
          class="logistic-badge exact-center logistic"
          v-if="item.logisticdetails"
        >
          {{ item.logisticdetails ? item.logisticdetails.company_name : "" }}
        </div>
        <div class="logistic-badge unassigned exact-center" v-else>
          Unassigned
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="name" :style="{ color: orderStatus[item.status] }">
          {{ item.status ? item.status : "-" }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import {
  ORDER_STATUS,
  CURRENCY_SYMBOL,
  SERVER_URL,
} from "../../../../constants";

export default {
  name: "MerchantInnerTable",
  components: {},
  props: [
    "headers",
    "selectedType",
    "items",
    "totalItem",
    "page",
    "isFilter",
    "searchValue",
    "item",
    "keyValue"
  ],
  data() {
    return {
      orderStatus: ORDER_STATUS,
      deleteDialog: false,
      currencyArray: CURRENCY_SYMBOL,
      serverUrl: SERVER_URL,
      dateFilterType: [],
    };
  },
  methods: {
    filterClicked(value) {
      this.$emit("filterClicked", {
        value,
        type: this.selectedType,
        customer: this.item,
        isFilter: true,
      });
    },
    resetClicked() {
      this.$emit("filterClicked", {
        value: {},
        type: this.selectedType,
        customer: this.item,
        isFilter: false,
      });
    },
    updatePagination(value) {
      const type = this.selectedType;
      const page = value;
      this.$emit("updatePagination", { type, page, customer: this.item });
    },
    onSearch(value) {
      this.$emit("onSearch", {
        value,
        type: this.selectedType,
        customer: this.item,
      });
    },
    formatDate(date) {
      return moment(date).format("Do MMM,YYYY | h:mm a");
    },
    formatProductDate(date) {
      return moment(date).format("Do MMM,YY");
    },
    formatPrice(price, quantity) {
      const total = price * quantity;
      return total;
    },
    onClickReset(item) {
      this.usersItems.map((obj) => {
        if (obj.id === item.id) obj.isResetSelected = !obj.isResetSelected;
        else obj.isResetSelected = false;
      });
    },
    deleteClicked() {
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.deleteDialog = false;
    },
    onDeleteConfirm() {
      this.deleteDialog = false;
    },
    setDateFilterType() {
      this.dateFilterType = [
        {
          name: "Created Date",
          value: "created_at",
        },
      ];
    },
  },
  created() {
    this.setDateFilterType();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.merchant-inner-table-container {
  ::v-deep .v-data-footer__select {
    display: none;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
    background: $white-color !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 18px;
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep
    .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td
    > .v-icon {
    display: none !important;
  }
  ::v-deep .v-data-table > .v-data-footer {
    border: none;
  }

  background: $white-color !important;
  .inner-table-header {
    margin-bottom: 2.5rem;
    .table-title {
      font-weight: 600;
      font-size: 1rem;
      color: $secondary-color;
    }
  }
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .role {
    width: 128px;
    height: 36px;
    border-radius: 31px;
    font-weight: 500;
    font-size: 12px;
  }
  .super-user {
    background: #ffede3;
    color: #e87d1b;
  }
  .normal-user {
    background: #eeefff;
    color: #1b25c6;
  }
  .product-img {
    width: 50px;
    height: 50px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 12px 6px;
    img {
      width: 100%;
    }
  }
  .description {
    color: $secondary-color;
    font-weight: 600;
    font-size: 12px;
  }
  .price {
    color: #39c25f;
    font-weight: 600;
    font-size: 14px;
  }
  .logistic-badge {
    width: 160px;
    padding: 10px;
    border-radius: 6px;
  }
  .unassigned {
    background: #ffeddf;
    font-weight: 600;
    font-size: 12px;
  }
  .logistic {
    background: rgba(212, 5, 17, 0.1);
    font-weight: 600;
    font-size: 12px;
  }
}
</style>
