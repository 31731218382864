<template>
  <div>
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <v-dialog :value="show" persistent :max-width="width">
      <div>
        <!-- title -->
        <div
          class="add-user-title pt-8 pl-6 pr-6 display-flex width-100 align-center justify-space-between"
        >
          {{ title }}
          <v-btn @click="onCancel" icon dark>
            <img src="@/assets/icons/close.svg" />
          </v-btn>
        </div>
        <!-- body -->
        <v-row class="no-gutters mt-4 ml-6 mr-6">
          <v-col>
            <v-row no-gutters>
              <v-col
                ><TextField
                  @change="getFormData($event, 'warehouse_name')"
                  label="Warehouse Name"
                  :required="true"
                  :value="formData.warehouse_name"
              /></v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                ><TextField
                  label="Post Code"
                  :value="formData.postalcode"
                  :required="true"
                  @input="getFormData($event, 'postalcode')"
                  input-type="Number"
                  min="0"
                  :debounce="true"
                  :key="postalcodeKey"
              /></v-col>
              <v-col class="ml-3"
                ><SelectField
                  label="Country"
                  :required="true"
                  :value="formData.country"
                  :data-set="countryDataSet"
                  @change="getFormData($event, 'country')"
                  :key="countryKey"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                ><TextField
                  label="State"
                  :value="formData.state"
                  @change="getFormData($event, 'state')"
                  :key="stateKey"
              /></v-col>
              <v-col class="ml-3"
                ><TextField
                  label="City"
                  :value="formData.cityortown"
                  @change="getFormData($event, 'cityortown')"
                  :key="cityKey"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  label="Address"
                  rows="5"
                  :required="true"
                  :value="formData.address"
                  @change="getFormData($event, 'address')"
                  :key="addressKey"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                ><TextField
                  label="Building Name/Block Number"
                  :value="formData.block_number"
                  :required="true"
                  @change="getFormData($event, 'block_number')"
              /></v-col>
              <v-col class="ml-3"
                ><TextField
                  label="Floor - Unit Number"
                  :value="formData.floor_number"
                  :required="true"
                  @change="getFormData($event, 'floor_number')"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextField
                  label="Fax No"
                  :value="formData.fax_number"
                  @change="getFormData($event, 'fax_number')"
                  input-type="Number"
                  min="0"
                />
              </v-col>
              <v-col class="ml-3">
                <TextField
                  label="Phone No."
                  :value="formData.phone_number"
                  @change="getFormData($event, 'phone_number')"
                  input-type="Number"
                  min="0"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextField
                  label="Contact person"
                  :value="formData.contact_person"
                  @change="getFormData($event, 'contact_person')"
                  :required="true"
                />
              </v-col>
              <v-col class="ml-3">
                <TextField
                  :required="true"
                  label="Mobile No."
                  :value="formData.contact_number"
                  @change="getFormData($event, 'contact_number')"
                  input-type="Number"
                  min="0"
              /></v-col>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <div class="locate-in-map justify-space-between mb-3">
                  <div>Locate in Map</div>
                  <div>
                    Latitude : {{ location.lat ? location.lat : center.lat }} |
                    Longitude :
                    {{ location.lng ? location.lng : center.lng }}
                  </div>
                </div>
                <GmapMap
                  :center="center"
                  :zoom="mapZoom"
                  map-type-id="roadmap"
                  style="height: 200px"
                  :key="mapKey"
                  @click="markerDragged"
                >
                  <GmapMarker
                    :position="location"
                    :clickable="true"
                    :draggable="true"
                    @click="center = location"
                    @dragend="markerDragged"
                    :icon="{ url: require('@/assets/icons/mylocation.svg') }"
                  />
                </GmapMap>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- footer -->
        <v-row class="justify-end footer-section pb-5 mt-8">
          <div class="justify-end">
            <Button
              label="Close"
              type="cancel"
              width="94px"
              @click="onCancel"
            />
            <Button
              class="ml-3"
              label="Save"
              type="save"
              width="94px"
              @click="onSave"
              :disabled="isSubmitDisabled() || isLoading"
              :loading="isLoading"
            />
          </div>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { Country } from "country-state-city";
import { DEFAULT_COUNTRY } from "../../../../constants";
import {
  getAddressData,
  getAddressFromLatLng,
} from "../../../services/utils.js";
export default {
  name: "MerchantAddWarehouse",
  mixins: [validationMixin],
  validations: {
    formData: {
      warehouse_name: { required },
      block_number: { required },
      floor_number: { required },
      postalcode: { required },
      country: { required },
      address: { required },
      contact_number: { required },
      contact_person: { required },
    },
  },
  props: ["show", "width", "title", "item", "isEdit"],
  data() {
    return {
      isLoading: false,
      markers: [],
      formData: {
        warehouse_name: "",
        block_number: "",
        floor_number: "",
        postalcode: "",
        country: "",
        state: "",
        cityortown: "",
        address: "",
        fax_number: "",
        phone_number: "",
        contact_person: "",
        contact_number: "",
      },
      countryDataSet: [],
      stateDataSet: [],
      cityDataSet: [],
      isNotification: false,
      notificationMessage: "",
      notificationKey: 0,
      stateDisabled: false,
      mapKey: 10,
      countryKey: 20,
      stateKey: 30,
      cityKey: 40,
      postalcodeKey: 60,
      addressKey: 70,
      location: { lat: 0, lng: 0 },
      center: { lat: 1.3521, lng: 103.8198 },
      mapZoom: 12,
    };
  },
  computed: {
    ...mapGetters("merchant/authentication", ["loggedUser"]),
  },
  methods: {
    ...mapActions({
      addWarehouse: "merchant/warehouse/addWarehouse",
      updateWarehouse: "merchant/warehouse/updateWarehouse",
    }),
    async markerDragged(event) {
      this.location = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      await getAddressFromLatLng(this.location, (address) => {
        const country = this.countryDataSet.find(
          (obj) => obj.name === address.country
        );
        const state = address.administrative_area_level_1
          ? address.administrative_area_level_1
          : address.locality;
        this.formData.postalcode = address.postal_code;
        this.formData.country = country;
        this.formData.state = state;
        this.formData.cityortown = address.administrative_area_level_2;
        this.formData.address = address.fullAddress ? address.fullAddress : "";
        this.postalcodeKey += 1;
        this.countryKey += 1;
        this.stateKey += 1;
        this.cityKey += 1;
        this.addressKey += 1;
      });
    },
    setEditFormData() {
      //Warehouse Data
      this.formData.postalcode = this.item?.postalcode;
      this.formData.warehouse_name = this.item?.warehouse_name;
      this.formData.block_number = this.item?.block_number;
      this.formData.floor_number = this.item?.floor_number;
      this.formData.country = country;
      const country = this.countryDataSet.find(
        (obj) => obj.name === this.item.country
      );
      this.formData.country = country;
      this.formData.state = this.item?.state ? this.item.state : "";
      this.formData.cityortown = this.item?.cityortown
        ? this.item.cityortown
        : "";
      this.formData.address = this.item?.address;
      this.formData.phone_number = this.item?.phone_number;
      this.formData.contact_person = this.item?.contact_person;
      this.formData.contact_number = this.item?.contact_number;
      this.formData.fax_number = this.item?.fax_number;
      //Maps
      this.center = { lat: this.item?.latitude, lng: this.item?.longitude };
      this.location = { lat: this.item?.latitude, lng: this.item?.longitude };
      this.mapZoom = 16;
      this.mapKey += 1;
    },
    setDefaultCountry() {
      const country = this.countryDataSet.find(
        (obj) => obj.name === DEFAULT_COUNTRY
      );
      this.formData.country = country;
      this.formData.state = DEFAULT_COUNTRY;
      this.formData.cityortown = DEFAULT_COUNTRY;
    },
    onCancel() {
      this.$emit("close");
    },
    async onSave() {
      this.isLoading = true;
      try {
        if (this.loggedUser.company_id)
          this.formData.company_id = this.loggedUser.company_id;
        if (this.formData.country)
          this.formData.country = this.formData?.country?.name;
        this.formData.state = this.formData?.state
          ? this.formData?.state
          : '';
        this.formData.cityortown = this.item?.cityortown
          ? this.item.cityortown
          : '';
        if (this.location.lat) this.formData.latitude = this.location.lat;
        if (this.location.lng) this.formData.longitude = this.location.lng;
        this.formData.fax_number = this.formData.fax_number
          ? this.formData.fax_number
          : '';
        this.formData.phone_number = this.formData.phone_number
          ? this.formData.phone_number
          : '';
        this.formData.contact_person = this.formData.contact_person
          ? this.formData.contact_person
          : '';
        this.formData.contact_number = this.formData.contact_number
          ? this.formData.contact_number
          : '';
        this.isEdit
          ? await this.updateWarehouse({
              id: this.item.id,
              data: this.formData,
            })
          : await this.addWarehouse(this.formData);
        this.isLoading = false;
        this.$emit("saveClicked");
      } catch (err) {
        this.notificationMessage = err.response.data.message;
        this.isNotification = true;
        this.notificationKey += 1;
        this.isLoading = false;
      }
    },
    async getFormData(value, fieldName) {
      if (fieldName === "postalcode") {
        await getAddressData(value, (address, latlng) => {
          const country = this.countryDataSet.find(
            (obj) => obj.name === address.country
          );
          const state = address.administrative_area_level_1
            ? address.administrative_area_level_1
            : address.locality;
          this.formData.country = country;
          this.formData.state = state;
          this.formData.cityortown = address.administrative_area_level_2;
          this.formData.address = address.fullAddress
            ? address.fullAddress
            : "";
          this.location = latlng;
          this.center = latlng;
          this.mapKey += 1;
          this.countryKey += 1;
          this.stateKey += 1;
          this.cityKey += 1;
          this.addressKey += 1;
        });
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
  },
  created() {
    this.countryDataSet = Country.getAllCountries();
    this.setDefaultCountry();
    if (this.show && this.isEdit) {
      this.setEditFormData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.v-dialog > * {
  background: #ffffff !important;
  min-height: 550px;
  // overflow-y: scroll;
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.footer-section {
  width: 100%;
}
.locate-in-map {
  color: #808080;
  font-size: 12px;
}
</style>
