<template>
  <div class="security-container mt-4 mb-4">
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <div class="header">
      <h1 class="p-4">Security</h1>
    </div>
    <div class="security-settings mt-4 pl-4 pt-4" v-if="loggedUserPermission.view_edit_security">
      <h2 class="ml-2">Password</h2>
      <h3 class="ml-2">Change Password</h3>
      <div class="password-field-container pl-2 pr-2" :key="textFieldKey">
        <div class="password-field">
          <TextField
            label="Current Password"
            placeholder="Enter Current Password"
            :required="true"
            :value="formData.currentPassword"
            inputType="password"
            @change="getFormData($event, 'currentPassword')"
          />
        </div>
        <div class="password-field">
          <TextField
            label="New Password"
            placeholder="Enter New Password"
            :required="true"
            :value="formData.newPassword"
            inputType="password"
            @change="getFormData($event, 'newPassword')"
          />
        </div>
        <div class="password-field">
          <TextField
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            :required="true"
            :value="formData.confirmPassword"
            inputType="password"
            @change="getFormData($event, 'confirmPassword')"
          />
        </div>
      </div>
      <div class="pass-btn-container pl-2">
        <Button
          type="primary"
          label="Save"
          width="83px"
          :disabled="isSubmitDisabled() || isLoading"
          :loading="isLoading"
          @click="onClickSave"
        />
      </div>
    </div>
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Security",
  mixins: [validationMixin],
  validations: {
    formData: {
      currentPassword: { required },
      newPassword: { required, sameAs: sameAs("confirmPassword") },
      confirmPassword: { required, sameAs: sameAs("newPassword") },
    },
  },
  data() {
    return {
      isLoading: false,
      formData: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      notificationMessage: "",
      isNotification: false,
      notificationKey: 0,
      textFieldKey: 20,
    };
  },
  computed: {
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      changePassword: "merchant/authentication/changePassword",
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    async onClickSave() {
      try {
        this.isLoading = true;
        await this.changePassword({
          currentPassword: this.formData.currentPassword,
          newPassword: this.formData.newPassword,
        });
        this.isLoading = false;
        this.notificationMessage = "Password updated successfully!";
        this.isNotification = true;
        this.notificationKey += 1;
        this.formData.currentPassword = "";
        this.formData.newPassword = "";
        this.formData.confirmPassword = "";
        this.textFieldKey += 1;
      } catch (err) {
        this.isLoading = false;
        this.notificationMessage = err.response.data.message;
        this.isNotification = true;
        this.notificationKey += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.security-container {
  height: 27.313rem;
  background: $white-color;
  border: 1px solid #e5e5e5;
  .header {
    border-bottom: 1px solid #e1e1e1;
    h1 {
      font-weight: 500;
      font-size: 1.25rem;
      color: $secondary-color;
    }
  }
  .security-settings {
    display: flex;
    width: 100%;
    flex-direction: column;
    h2 {
      font-weight: 500;
      font-size: 1rem;
      color: $secondary-color;
      margin-bottom: 0.5rem;
    }
    h3 {
      font-weight: 500;
      font-size: 0.75rem;
      color: #4a557180;
    }
  }
  .password-field-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1.75rem;
  }
  .password-field {
    min-width: 14rem;
    width: 40%;
    margin-right: 0.5rem;
  }
  .pass-btn-container {
    margin-top: 1rem;
  }
}
</style>
