<template>
  <div>
    <CommonToolbar
      title="Warehouse"
      :secondaryButtonLabel="loggedUserPermission.view_warehouse && 'Export'"
      :isSearch="loggedUserPermission.view_warehouse"
      @search="onSearch"
      @secondaryButtonClick="exportClick"
      :showDatePickers="true"
      :primaryButtonLabel="
        loggedUserPermission.edit_warehouse &&
        loggedUserPermission.view_warehouse &&
        'Add Warehouse'
      "
      @primaryButtonClick="addClick"
      @filterClicked="filterClicked"
      @resetClicked="resetClicked"
      :isFilter="fromDate && toDate"
      :dateFilterType="dateFilterType"
      :showDateFilter="loggedUserPermission.view_warehouse"
    />
    <SubHeader :items="items" />
    <div class="customer-table-container">
      <TableSection v-if="loggedUserPermission.view_warehouse" :key="tableKey" />
      <NoAccess v-else class="no-content-container" />
      <AddWarehouse
        :show="show"
        @close="closeClicked"
        width="691"
        title="Add Warehouse"
        :key="addWarehouseKey"
        @saveClicked="refreshTable"
      />
      <div class="note-container width-100 justify-end pr-14 mt-3">
        <div class="note-card">
          <div>Note:</div>
          <div>If you dont have a Warehouse, add your Store Address.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MERCHANT_SUB_NAV_ITEMS } from "../../../../constants";
import AddWarehouse from "@/components/Merchant/Warehouse/AddWarehouse";
import TableSection from "@/components/Merchant/Warehouse/TableSection";

export default {
  name: "MerchantWarehouse",
  data() {
    return {
      show: false,
      items: MERCHANT_SUB_NAV_ITEMS,
      tableKey: 0,
      addWarehouseKey: 10,
      dateFilterType: [
        {
          name: "Created Date",
          value: "created_at",
        },
      ],
    };
  },
  components: { TableSection, AddWarehouse },
  computed: {
    ...mapGetters("merchant/warehouse", ["fromDate", "toDate", "totalWarehouse"]),
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
    ...mapGetters("merchant/count", ["count"]),
  },
  watch: {
    totalWarehouse() {
      this.items[3].count = this.totalWarehouse;
    },
  },
  methods: {
    ...mapActions({
      setSearchQuery: "merchant/warehouse/setSearchQuery",
      exportWarehouse: "merchant/warehouse/exportWarehouse",
      setDateRange: "merchant/warehouse/setDateRange",
      getMerchantTabCount: "merchant/count/getMerchantTabCount",
      setPage: "merchant/warehouse/setPage",
    }),
    refreshTable() {
      this.show = false;
      this.setSearchQuery("");
      this.tableKey += 1;
      this.addWarehouseKey += 1;
    },
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    closeClicked() {
      this.show = false;
      this.addWarehouseKey += 1;
    },
    filterClicked(value) {
      this.setPage(1);
      this.setDateRange(value);
      this.tableKey += 1;
    },
    resetClicked() {
      this.setDateRange({});
      this.tableKey += 1;
    },
    async exportClick() {
      await this.exportWarehouse();
    },
    addClick() {
      this.show = true;
    },
  },
  async created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setDateRange({});
    await this.getMerchantTabCount();
    this.items[0].count = this.count.orderCount;
    this.items[1].count = this.count.productCount;
    this.items[2].count = this.count.customerCount;
    this.items[3].count = this.count.warehouseCount;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.customer-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
  .note-card {
    width: 378px;
    padding: 20px;
    background: #ffeecc;
    border-radius: 6px;
    div {
      color: $secondary-color;
      font-size: 12px;
    }
  }
}
</style>
