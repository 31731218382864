<template>
  <div class="filter-outer-container">
    <div
      class="add-user-title ml-6 pt-8 display-flex width-100 align-center justify-space-between"
    >
      Filter
    </div>
    <div class="sub-title mb-3 mt-4  pl-6">Product</div>
    <v-row class="no-gutters pl-6 pr-6">
      <v-col>
        <v-row no-gutters>
          <v-col>
            <SelectField
              label="Date Type"
              :value="formData.type"
              :data-set="['NA', ...typeDataSet]"
              @change="getFormData($event, 'type')"
            />
          </v-col>
          <v-col class="ml-3">
            <DateSelectField
              label="Date Range"
              :isDatePicker="true"
              :data-set="['NA', ...dateCreatedDataSet]"
              @change="getFormData($event, 'dateRange')"
              :disabled="!formData.type"
              :key="dateKey"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            ><SelectField
              label="Category"
              :value="formData.productCategoryFilter"
              :data-set="categories"
              @focus="getAllCategory"
              @change="getFormData($event, 'productCategoryFilter')"
              :loading="categoryLoading"
          /></v-col>
          <v-col class="ml-3"
            ><SelectField
              label="Name"
              :value="formData.productNameFilter"
              :data-set="productsByCategory"
              @change="getFormData($event, 'productNameFilter')"
              :disabled="productDisabled"
              itemText="product_name"
          /></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            ><SelectField
              label="Brand"
              :value="formData.productBrandFilter"
              :data-set="['NA', ...brands]"
              @focus="getBrandDropDown"
              @change="getFormData($event, 'productBrandFilter')"
              :loading="brandLoading"
          /></v-col>
          <v-col class="ml-3"></v-col>
        </v-row>
        <div class="sub-title mb-3">Sort</div>
        <v-row no-gutters>
          <v-col
            ><SelectField
              label="Show"
              :value="formData.sortType"
              :data-set="['NA', ...sortFilterDataSet]"
              @change="getFormData($event, 'sortType')"
          /></v-col>
          <v-col class="ml-3"></v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- footer -->
    <v-row class="justify-end footer-section pb-5 mt-8">
      <div class="justify-end">
        <Button label="Reset" type="cancel" width="94px" @click="onCancel" />
        <Button
          class="ml-3"
          label="Filter"
          type="save"
          width="94px"
          @click="onFilterClicked"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

export default {
  name: "ProductFilter",
  mixins: [validationMixin],
  validations: {
    formData: {},
  },
  props: [],
  data() {
    return {
      isLoading: false,
      markers: [],
      formData: {
        dateRange: "",
        type: "",
        productNameFilter: "",
        productCategoryFilter: "",
        productBrandFilter: "",
        sortType: "",
      },
      dateKey: 10,
      dateCreatedDataSet: ["Today", "This Week", "This Month"],
      sortFilterDataSet: [
        { name: "Most popular first", key: "Most popular first" },
        { name: "Least popular first", key: "Least popular first" },
        { name: "Latest to Earliest sold", key: "Most sold first" },
        { name: "Earliest to Latest sold", key: "Least sold first" },
      ],
      typeDataSet: [{ name: "Created Date", key: "created_at" }],
      orderStatusDataSet: [
        { name: "Pending Orders", key: "Order placed" },
        { name: "Orders Ready for Shipping", key: "Parcel ready for shipping" },
        { name: "Parcel at Sorting Center", key: "Parcel at sorting center" },
        {
          name: "Parcel picked by Logistics to Customer",
          key: "Parcel picked by logistics to Customer",
        },
        {
          name: "Parcel picked by Logistics",
          key: "Parcel picked by logistics",
        },
        { name: "Delivered Orders", key: "Order delivered" },
      ],
      logisticLoading: false,
      categoryLoading: false,
      brandLoading: false,
      productDisabled: true,
    };
  },
  computed: {
    ...mapGetters("merchant/orders", ["allLogistics"]),
    ...mapGetters("merchant/product", [
      "productsByCategory",
      "categories",
      "brands",
    ]),
  },
  methods: {
    ...mapActions({
      setFilterValues: "merchant/product/setFilterValues",
      getLogistics: "merchant/orders/getLogistics",
      getProductCategories: "merchant/product/getProductCategories",
      getProductsByCategoryInProduct:
        "merchant/product/getProductsByCategoryInProduct",
      getProductBrands: "merchant/product/getProductBrands",
    }),
    onCancel() {
      this.setFilterValues(null);
      this.$emit("onClose");
    },
    async getAllCategory() {
      this.categoryLoading = true;
      await this.getProductCategories();
      this.categoryLoading = false;
    },
    async getLogisticsDropDown() {
      this.logisticLoading = true;
      await this.getLogistics();
      this.logisticLoading = false;
    },
    async getBrandDropDown() {
      this.brandLoading = true;
      await this.getProductBrands();
      this.brandLoading = false;
    },
    async onFilterClicked() {
      if (this.formData.productNameFilter)
        delete this.formData.productCategoryFilter;
      await this.setFilterValues(this.formData);
      this.$emit("onFilter");
    },
    async getFormData(value, fieldName) {
      if (value === "NA") {
        if (fieldName === "type") {
          this.formData.dateRange = "";
          this.dateKey += 1;
        }
        this.formData[fieldName] = "";
        await this.onFilterClicked();
      }
      if (fieldName === "dateRange" && value?.value === "NA") {
        this.formData[fieldName] = "";
        await this.onFilterClicked();
      }
      if (fieldName === "productCategoryFilter") {
        await this.getProductsByCategoryInProduct({ category: value });
        this.productDisabled = false;
        this.formData[fieldName] = value;
        return;
      }
      if (fieldName === "sortType") {
        this.formData.sortType = value.key;
        return;
      }
      if (fieldName === "statusFilter") {
        this.formData.statusFilter = value.key;
        return;
      }
      if (fieldName === "type") {
        this.formData.type = value.key;
        return;
      }
      if (fieldName === "logisticFilter") {
        this.formData.logisticFilter = value.id;
        return;
      }
      if (fieldName === "productNameFilter") {
        this.formData.productNameFilter = value.product_name;
        return;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
  },
  async created() {
    this.getLogisticsDropDown();
    this.getAllCategory();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.sub-title {
  font-weight: 500;
  font-size: 13px;
  color: #4a5571;
}
.filter-outer-container {
  width: 780px;
  min-height: 400px;
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}
.footer-section {
  width: 100%;
}
.locate-in-map {
  color: #808080;
  font-size: 12px;
}
</style>
