<template>
  <div :key="tableKey" class="warehouse-container pl-14 pr-14">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Warehouse`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Warehouse? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="totalWarehouse === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="warehouseHeaders"
        :items="allWarehouse"
        :server-items-length="totalWarehouse"
        :options="{ page: page, itemsPerPage: limit }"
        @update:page="updatePagination($event)"
        @update:items-per-page="updatePerPage($event)"
        item-key="id"
        hide-default-footer
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template>

        <template v-slot:item.warehouse_name="{ item }">
          <div class="display-flex align-center">
            <div class="name ml-4">
              {{ item.warehouse_name ? item.warehouse_name : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.contact_person="{ item }">
          <div class="display-flex align-center">
            <div class="name ml-4">
              {{ item.contact_person ? item.contact_person : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.contact_number="{ item }">
          <div class="display-flex align-center">
            <div class="name ml-4">
              {{ item.contact_number ? item.contact_number : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.address="{ item }">
          <div class="address-1">
            {{ item.block_number ? item.block_number + ", " : ""
            }}{{ item.floor_number ? item.floor_number + ", " : ""
            }}{{ item.address ? item.address : "-" }}
          </div>
          <div class="address-2" v-if="item.address">
            {{ item.cityortown ? item.cityortown + ", " : ""
            }}{{ item.state ? item.state + ", " : ""
            }}{{ item.country ? item.country : "" }}
          </div>
        </template>

        <template v-slot:item.phone_number="{ item }">
          <div class="name">
            {{ item.phone_number ? item.phone_number : "-" }}
          </div>
        </template>

        <template v-slot:item.fax_number="{ item }">
          <div class="name">{{ item.fax_number ? item.fax_number : "-" }}</div>
        </template>

        <template v-slot:item.actions="props">
          <div class="action-buttons display-flex align-center">
            <v-edit-dialog :return-value.sync="props.item.action">
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @editClicked="editClicked(props.item)"
                  @deleteClicked="deleteClicked(props.item)"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>
      </v-data-table>
    </div>

    <AddWarehouse
      :show="showAddWarehouse"
      @close="showAddWarehouse = false"
      width="691"
      title="Edit Warehouse"
      :key="warehouseKey"
      :item="selectedItem"
      :isEdit="true"
      @saveClicked="refresh"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import lodash from "lodash";
import AddWarehouse from "@/components/Merchant/Warehouse/AddWarehouse";
import { ITEMS_PER_PAGE } from "../../../../constants";

export default {
  name: "MerchantWarehouseTable",
  components: { AddWarehouse },
  data() {
    return {
      itemsPerPage: ITEMS_PER_PAGE,
      isLoading: false,
      deleteDialog: false,
      showAddWarehouse: false,
      warehouseHeaders: [
        {
          text: "Warehouse Name",
          align: "start",
          sortable: false,
          value: "warehouse_name",
        },
        {
          text: "Contact Person",
          align: "start",
          sortable: false,
          value: "contact_person",
        },
        {
          text: "Mobile No",
          align: "start",
          sortable: false,
          value: "contact_number",
        },
        {
          text: "Address",
          align: "start",
          sortable: false,
          value: "address",
          width: "280",
        },
        {
          text: "Phone No.",
          align: "start",
          sortable: false,
          value: "phone_number",
        },
        {
          text: "Fax No",
          align: "start",
          sortable: false,
          value: "fax_number",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      allWarehouse: [],
      tableKey: 0,
      selectedItem: {},
      warehouseKey: 10,
    };
  },
  computed: {
    ...mapGetters("merchant/warehouse", [
      "warehouses",
      "totalWarehouse",
      "page",
      "limit",
    ]),
    ...mapGetters("merchant/authentication", [
      "loggedUser",
      "loggedUserPermission",
    ]),
  },
  methods: {
    ...mapActions({
      getWarehouses: "merchant/warehouse/getWarehouses",
      setPage: "merchant/warehouse/setPage",
      setLimit: "merchant/warehouse/setLimit",
      deleteWarehouse: "merchant/warehouse/deleteWarehouse",
    }),
    editClicked(item) {
      this.selectedItem = item;
      this.showAddWarehouse = true;
      this.warehouseKey += 1;
    },
    deleteClicked(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.deleteDialog = false;
      this.selectedItem = {};
    },
    async onDeleteConfirm() {
      this.deleteDialog = false;
      await this.deleteWarehouse(this.selectedItem);
      this.selectedItem = {};
      await this.getAllWarehouse();
    },
    async getAllWarehouse() {
      try {
        await this.getWarehouses();
        this.allWarehouse = lodash.cloneDeep(this.warehouses);
      } catch (err) {
        console.log(err);
      }
    },
    async refresh() {
      this.showAddWarehouse = false;
      this.isLoading = true;
      await this.getAllWarehouse();
      this.tableKey += 1;
      this.isLoading = false;
    },
    async updatePagination(value) {
      this.isLoading = true;
      this.setPage(value);
      await this.getAllWarehouse();
      this.isLoading = false;
    },
    async updatePerPage(value) {
      this.isLoading = true;
      this.setLimit(value);
      await this.getAllWarehouse();
      this.isLoading = false;
    },
  },
  async created() {
    if (!this.loggedUserPermission.edit_warehouse) {
      this.warehouseHeaders.splice(this.warehouseHeaders.length - 1, 1);
    }
    await this.refresh();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}

::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.inner-table-td {
  background: $white-color !important;
}
.warehouse-container {
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
}
</style>
