<template>
  <div>
    <CommonToolbar
      title="Users"
      :primaryButtonLabel="
        loggedUserPermission.view_users && loggedUserPermission.edit_users && 'Add User'
      "
      :secondaryButtonLabel="loggedUserPermission.view_users && 'Export'"
      :isSearch="loggedUserPermission.view_users"
      @search="onSearch"
      @primaryButtonClick="addClick"
      @secondaryButtonClick="exportClick"
      @filterClicked="filterClicked"
      @resetClicked="resetClicked"
      :isFilter="fromDate && toDate"
      :dateFilterType="dateFilterType"
      :showDateFilter="loggedUserPermission.view_users"
    />
    <TableSection
      v-if="loggedUserPermission.view_users"
      :key="tableKey"
      @refresh="refreshTable"
    />
    <NoAccess v-else class="no-content-container" />
    <AddUser
      :key="addUserKey"
      :show="show"
      width="928"
      @close="closeClicked"
      title="Add User"
      @saveClicked="refreshTable"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddUser from "./AddUser.vue";
import TableSection from "./TableSection.vue";
export default {
  name: "MerchantUsers",
  components: {
    AddUser,
    TableSection,
  },
  data() {
    return {
      show: false,
      tableKey: 0,
      addUserKey: 10,
      dateFilterType: [
        {
          name: "Created Date",
          value: "created_at",
        },
        {
          name: "Last Login Date",
          value: "last_login",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("merchant/users", ["fromDate", "toDate"]),
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      setSearchQuery: "merchant/users/setSearchQuery",
      exportUsers: "merchant/users/exportUsers",
      setDateRange: "merchant/users/setDateRange",
      fetchMerchantRoles: "merchant/settings/fetchMerchantRoles",
      setPage: "merchant/users/setPage",
    }),
    addClick() {
      this.addUserKey += 1;
      this.show = true;
    },
    refreshTable() {
      this.show = false;
      this.setSearchQuery("");
      this.tableKey += 1;
      this.addUserKey += 1;
    },
    closeClicked() {
      this.show = false;
      this.addUserKey += 1;
    },
    filterClicked(value) {
      this.setPage(1);
      this.setDateRange(value);
      this.tableKey += 1;
    },
    resetClicked() {
      this.setDateRange({});
      this.tableKey += 1;
    },
    async exportClick() {
      await this.exportUsers();
    },
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
  },

  async created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setDateRange({});
    await this.fetchMerchantRoles();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
</style>
