var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-container pl-14 pr-14"},[_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete Order","cancel":"Cancel","content":"Are you sure you want to delete the selected Order? ","accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}}),(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center"}):_c('div',[(_vm.totalOrders === 0)?_c('div',{staticClass:"no-content-container"},[_c('NoContent')],1):_c('v-data-table',{attrs:{"headers":_vm.ordersHeaders,"items":_vm.orders,"expanded":_vm.expanded,"item-key":"id","item-class":_vm.itemRowBackground,"hide-default-footer":"","server-items-length":_vm.totalOrders,"options":{ page: _vm.page, itemsPerPage: _vm.limit }},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){return _vm.updatePagination($event)},"update:items-per-page":function($event){return _vm.updatePerPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":"","items-per-page-options":_vm.itemsPerPage,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.tracking_id",fn:function(props){return [_c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"expand-icon mr-4 cursor-pointer",on:{"click":function($event){return _vm.expandClicked(props, 'Customers', props.item.type)}}},[(!props.item.customerSelected)?_c('img',{attrs:{"src":require("@/assets/icons/table-expansion-closed.svg"),"alt":"expand-icon"}}):_c('img',{attrs:{"src":require("@/assets/icons/table-expansion-open.svg"),"alt":"expand-icon"}})]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(props.item.tracking_id ? props.item.tracking_id : "-")+" ")])])]}},{key:"item.product_category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_category : "-")+" ")])]}},{key:"item.product_code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_code : "-")+" ")])]}},{key:"item.order_no",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.orderdetail.orderId ? item.orderdetail.orderId : "-")+" ")])]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.productdetails.product_image)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.productdetails.product_image)),"name":item.productdetails.product_name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.productdetails.product_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"ml-2 name"},[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_name : "-")+" ")])],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"description ellipsis"},on),[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_description : "-")+" ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_description : "-")+" ")])])]}},{key:"item.product_quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.product_quantity ? item.product_quantity : "-")+" ")])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.createdby && item.createdby.name ? item.createdby.name : "-"))])]}},{key:"item.uom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.uom ? item.uom : "-"))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.created_at ? _vm.formatProductDate(item.created_at) : "-")+" ")])]}},{key:"item.createdby",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.createdby && item.createdby.name ? item.createdby.name : "-")+" ")])]}},{key:"item.expected_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.expected_delivery_date ? _vm.formatProductDate(item.expected_delivery_date) : "-")+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(item.productdetails.currency ? _vm.currencyArray[item.productdetails.currency] : "")+" "+_vm._s(item.productdetails ? _vm.formatPrice( item.productdetails.product_price, item.product_quantity ) : "-")+" ")])]}},{key:"item.logistics",fn:function(ref){
var item = ref.item;
return [(
            item.logisticdetails &&
              item.status !== 'Parcel ready for shipping'
          )?_c('div',{staticClass:"logistic-badge exact-center logistic"},[_vm._v(" "+_vm._s(item.logisticdetails ? item.logisticdetails.company_name : "")+" ")]):_c('v-edit-dialog',{ref:'assignLogistic' + item.id,attrs:{"return-value":item.logistics,"persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "logistics", $event)},"update:return-value":function($event){return _vm.$set(item, "logistics", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('AssignLogisticModal',{key:_vm.assignedKey,attrs:{"item":item,"isReassign":item.status === 'Parcel ready for shipping'},on:{"closeClicked":function($event){return _vm.closeAssignModal(item.id)},"saveClicked":function($event){return _vm.logisticAssigned(item.id)}}})]},proxy:true}],null,true)},[(item.status === 'Parcel ready for shipping')?_c('div',{staticClass:"logistic-badge unassigned exact-center cursor-pointer",attrs:{"item.status":""}},[_vm._v(" "+_vm._s(item.logisticdetails.company_name)+" ")]):_c('div',{staticClass:"display-flex"},[_c('div',{staticClass:"logistic-badge unassigned exact-center cursor-pointer"},[_vm._v(" Unassigned ")]),(
                _vm.getDateforSameCondition(item) ||
                  _vm.getDateforAfterCondition(item)
              )?_c('img',{staticClass:"alert",attrs:{"src":require("@/assets/icons/alert.png"),"alt":"alert"}}):_vm._e()])])]}},{key:"item.status",fn:function(props){return [_c('div',{staticClass:"name button cursor-pointer",style:({ color: _vm.orderStatus[props.item.status] }),on:{"click":function($event){return _vm.expandClicked(props, 'Orders', props.item.type)}}},[_vm._v(" "+_vm._s(props.item.status ? props.item.status : "-")+" ")])]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"inner-table-td disply-flex",attrs:{"colspan":headers.length}},[_c('InnerTable',{key:_vm.innerTableKey,attrs:{"item":item,"selectedType":item.type,"headers":item.innerHeaders,"items":item.innerItems}})],1)]}},{key:"item.actions",fn:function(props){return [(props.item.status && props.item.status !== 'Order placed')?_c('v-tooltip',{attrs:{"max-width":"300px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"more-options exact-center ml-2 cursor-pointer"},on),[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])]}}],null,true)},[_c('div',[_vm._v(_vm._s(props.item.status)+" - Action blocked!")])]):_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('v-edit-dialog',{attrs:{"return-value":props.item.action},on:{"update:returnValue":function($event){return _vm.$set(props.item, "action", $event)},"update:return-value":function($event){return _vm.$set(props.item, "action", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('InnerMoreOptions',{on:{"deleteClicked":function($event){return _vm.deleteClicked(props.item)},"editClicked":function($event){return _vm.editClicked(props.item)}}})]},proxy:true}],null,true)},[_c('div',{staticClass:"more-options exact-center ml-2 cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])])],1)]}}])})],1),_c('EditOrder',{key:_vm.addKey,attrs:{"show":_vm.showAddOrder,"item":_vm.selectedItem,"width":920,"title":"Edit Order"},on:{"close":function($event){_vm.showAddOrder = false},"saveClicked":_vm.saveClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }