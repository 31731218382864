var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-table-container pl-7 pr-7 width-100"},[(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center"}):_c('div',[(_vm.totalCustomers === 0)?_c('div',{staticClass:"no-content-container"},[_c('NoContent')],1):_c('v-data-table',{attrs:{"headers":_vm.customerHeaders,"items":_vm.customers,"expanded":_vm.expanded,"item-key":"id","hide-default-footer":"","show-expand":"","options":{ page: _vm.page, itemsPerPage: _vm.limit },"server-items-length":_vm.totalCustomers},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){return _vm.updatePagination($event)},"update:items-per-page":function($event){return _vm.updatePerPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":"","items-per-page-options":_vm.itemsPerPage,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.user_first_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.user_img)?_c('Avatar',{attrs:{"name":item.user_first_name,"url":(_vm.serverUrl + "/" + (item.user_img)),"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.user_first_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.user_first_name ? item.user_first_name : "-")+" "+_vm._s(item.user_last_name)+" ")])],1)]}},{key:"item.contact_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.contact_number ? item.contact_number : "-")+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.block_number ? item.block_number + ", " : "")+_vm._s(item.floor_number ? item.floor_number + ", " : "")+_vm._s(item.address ? item.address : "-")+" ")]),(item.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.cityortown ? item.cityortown + ", " : "")+_vm._s(item.state ? item.state + ", " : "")+_vm._s(item.country ? item.country : "")+" ")]):_vm._e()]}},{key:"item.email_address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.email_address ? item.email_address : "-")+" ")])]}},{key:"item.last_login",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_c('div',[_vm._v(_vm._s(item.last_login ? _vm.formatDate(item.last_login) : "-"))]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(item.last_login ? _vm.formatTime(item.last_login) : "")+" ")])])]}},{key:"item.last_order",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_c('div',[_vm._v(" "+_vm._s(item.lastorderdate ? _vm.formatDate(item.lastorderdate) : "-")+" ")]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(item.lastorderdate ? _vm.formatTime(item.lastorderdate) : "")+" ")])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_c('div',[_vm._v(" "+_vm._s(item.created_at ? _vm.formatDate(item.created_at) : "-")+" ")]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(item.created_at ? _vm.formatTime(item.created_at) : "")+" ")])])]}},{key:"item.actions",fn:function(props){return [_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('ActionButton',{staticClass:"mr-4",attrs:{"label":"Orders","count":props.item.ordercount,"icon":"orders","width":"124px","active":props.item.orderSelected},on:{"click":function($event){return _vm.expandClicked(props, 'Orders')}}})],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"inner-table-td disply-flex",attrs:{"colspan":headers.length}},[_c('InnerTable',{attrs:{"item":item,"selectedType":item.type,"headers":item.innerHeaders,"items":item.innerItems,"keyValue":_vm.innerTableKey,"totalItem":item.totalItem,"page":item.page,"isFilter":item.isFilter,"searchValue":item.searchValue},on:{"updatePagination":_vm.updateInnerTablePage,"onSearch":_vm.onSearch,"filterClicked":_vm.filterClicked}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }