var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-inner-table-container width-100"},[_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete Order","cancel":"Cancel","content":"Are you sure you want to delete the selected Order ? ","accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}}),_c('div',{staticClass:"inner-table-header display-flex justify-space-between align-center"},[_c('div',{staticClass:"display-flex align-center"},[(_vm.selectedType === 'Orders')?_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/icons/orders.svg")}}):_vm._e(),_c('div',{staticClass:"table-title ml-2"},[_vm._v(_vm._s(_vm.selectedType))]),_c('SearchInput',{staticClass:"ml-4",attrs:{"value":_vm.searchValue,"autofocus":true},on:{"change":_vm.onSearch}}),_c('DateFilter',{staticClass:"ml-4",attrs:{"isFilter":_vm.isFilter,"dateFilterType":_vm.dateFilterType,"keyValue":_vm.keyValue},on:{"filterClicked":_vm.filterClicked,"resetClicked":_vm.resetClicked}})],1)]),_c('v-data-table',{key:_vm.keyValue,attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","hide-default-footer":"","show-expand":"","server-items-length":_vm.totalItem,"options":{ page: _vm.page, itemsPerPage: 5 }},on:{"update:page":function($event){return _vm.updatePagination($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","show-first-last-page":""},on:{"update:options":updateOptions}})]}},{key:"item.tracking_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.tracking_id ? item.tracking_id : "-"))])]}},{key:"item.order_no",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.orderdetail.orderId ? item.orderdetail.orderId : "-")+" ")])]}},{key:"item.product_category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_category : "-")+" ")])]}},{key:"item.product_code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_code : "-")+" ")])]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.productdetails.product_image)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.productdetails.product_image)),"name":item.productdetails.product_name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.productdetails.product_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"ml-2 name"},[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_name : "-")+" ")])],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"description ellipsis"},on),[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_description : "-")+" ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(item.productdetails ? item.productdetails.product_description : "-")+" ")])])]}},{key:"item.product_quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.product_quantity ? item.product_quantity : "-")+" ")])]}},{key:"item.uom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.uom ? item.uom : "-"))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.created_at ? _vm.formatProductDate(item.created_at) : "-")+" ")])]}},{key:"item.expected_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.expected_delivery_date ? _vm.formatProductDate(item.expected_delivery_date) : "-")+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(item.productdetails.currency ? _vm.currencyArray[item.productdetails.currency] : "")+" "+_vm._s(item.productdetails ? _vm.formatPrice( item.productdetails.product_price, item.product_quantity ) : "-")+" ")])]}},{key:"item.logistics",fn:function(ref){
var item = ref.item;
return [(item.logisticdetails)?_c('div',{staticClass:"logistic-badge exact-center logistic"},[_vm._v(" "+_vm._s(item.logisticdetails ? item.logisticdetails.company_name : "")+" ")]):_c('div',{staticClass:"logistic-badge unassigned exact-center"},[_vm._v(" Unassigned ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name",style:({ color: _vm.orderStatus[item.status] })},[_vm._v(" "+_vm._s(item.status ? item.status : "-")+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }