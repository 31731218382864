<template>
  <div class="product-container pl-14 pr-14">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Product`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Product? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="totalProducts === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="productHeaders"
        :items="products"
        @update:page="updatePagination($event)"
        :expanded.sync="expanded"
        item-key="id"
        :options="{ page: page, itemsPerPage: limit }"
        :server-items-length="totalProducts"
        @update:items-per-page="updatePerPage($event)"
        hide-default-footer
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template>

        <template v-slot:item.product_name="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.product_image"
              :url="`${serverUrl}/${item.product_image}`"
              :name="item.product_name"
              size="50"
              radius="12"
            />
            <Avatar v-else :name="item.product_name" size="50" radius="12" />
            <div class="name ml-4">
              {{ item.product_name ? item.product_name : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.product_category="{ item }">
          <div class="name">
            {{ item.product_category ? item.product_category : "-" }}
          </div>
        </template>

        <template v-slot:item.soldcount="{ item }">
          <div class="name">
            {{ item.soldcount ? item.soldcount : "0" }}
          </div>
        </template>

        <template v-slot:item.product_width="{ item }">
          <div class="name">
            {{ item.product_length ? item.product_length : "0" }} x
            {{ item.product_width ? item.product_width : "0" }} x
            {{ item.product_height ? item.product_height : "0" }}
          </div>
        </template>

        <template v-slot:item.product_code="{ item }">
          <div class="name">
            {{ item.product_code ? item.product_code : "-" }}
          </div>
        </template>

        <template v-slot:item.product_brand="{ item }">
          <div class="name">
            {{ item.product_brand ? item.product_brand : "-" }}
          </div>
        </template>

        <template v-slot:item.consumption="{ item }">
          <div class="name">
            {{ item.consumption ? item.consumption : "-" }}
          </div>
        </template>

        <template v-slot:item.product_description="{ item }">
          <v-tooltip bottom max-width="500px">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="name ellipsis">
                {{ item.product_description ? item.product_description : "-" }}
              </div>
            </template>
            <div>
              {{ item.product_description ? item.product_description : "-" }}
            </div>
          </v-tooltip>
        </template>

        <template v-slot:item.uom="{ item }">
          <div class="name">{{ item.uom ? item.uom : "-" }}</div>
        </template>

        <template v-slot:item.product_price="{ item }">
          <div class="name price">
            <span v-if="item.currency === 'SGD'">S$</span>
            <span v-if="item.currency === 'INR'">₹</span>
            <span v-if="item.currency === 'USD'">$</span>
            {{ item.product_price ? item.product_price : "-" }}
          </div>
        </template>
        <template v-slot:item.actions="props">
          <div
            class="action-buttons display-flex align-center"
            v-if="props.item.actions"
          >
            <v-edit-dialog :return-value.sync="props.item.action">
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @editClicked="editClicked(props.item)"
                  @deleteClicked="deleteClicked(props.item)"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>
      </v-data-table>
    </div>
    <AddProduct
      :width="920"
      :show="showAddProduct"
      @close="showAddProduct = false"
      title="Edit Product"
      :item="selectedItem"
      :isEdit="true"
      :key="addKey"
      @save="refresh"
    />
  </div>
</template>

<script>
import AddProduct from "@/components/Merchant/Products/AddProduct";
import { mapActions, mapGetters } from "vuex";
import {
  SERVER_URL,
  ITEMS_PER_PAGE,
  CURRENCY_SYMBOL,
} from "../../../../constants";
export default {
  name: "MerchantProductTable",
  components: { AddProduct },
  data() {
    return {
      serverUrl: SERVER_URL,
      itemsPerPage: ITEMS_PER_PAGE,
      isLoading: false,
      showAddProduct: false,
      deleteDialog: false,
      productHeaders: [
        {
          text: "Product Category",
          align: "start",
          sortable: false,
          value: "product_category",
          width: "170px",
        },
        {
          text: "Product Code",
          align: "start",
          value: "product_code",
          width: "150px",
          sortable: false,
        },
        {
          text: "Product Name",
          align: "start",
          value: "product_name",
          width: "200px",
          sortable: false,
        },
        {
          text: "Brand",
          align: "start",
          value: "product_brand",
          width: "150px",
          sortable: false,
        },
        {
          text: "Description",
          align: "start",
          value: "product_description",
          width: "200px",
          sortable: false,
        },
        {
          text: "Total Sold",
          align: "start",
          value: "soldcount",
          width: "200px",
          sortable: false,
        },
        {
          text: "Dimension (LxWxH in CM)",
          align: "start",
          value: "product_width",
          width: "250px",
          sortable: false,
        },
        {
          text: "Consumption (In Days)",
          align: "start",
          value: "consumption",
          width: "230px",
          sortable: false,
        },
        {
          text: "Price",
          align: "start",
          value: "product_price",
          width: "150px",
          sortable: false,
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      expanded: [],
      selectedItem: {},
      addKey: 0,
      items: [
        {
          symbol: CURRENCY_SYMBOL["SGD"],
          name: "SGD",
        },
        { symbol: CURRENCY_SYMBOL["INR"], name: "INR" },
        { symbol: CURRENCY_SYMBOL["USD"], name: "USD" },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProducts: "merchant/product/getProducts",
      setPage: "merchant/product/setPage",
      deleteProduct: "merchant/product/deleteProduct",
      setLimit: "merchant/product/setLimit",
    }),
    async updatePagination(value) {
      this.setPage(value);
      await this.fetchProducts();
    },
    async updatePerPage(value) {
      this.setLimit(value);
      await this.fetchProducts();
    },
    async fetchProducts() {
      this.isLoading = true;
      await this.getProducts();
      this.isLoading = false;
    },
    async refresh() {
      await this.fetchProducts();
      this.showAddProduct = false;
      this.selectedItem = {};
    },
    editClicked(item) {
      this.selectedItem = item;
      this.addKey += 1;
      this.showAddProduct = true;
    },
    deleteClicked(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.deleteDialog = false;
    },
    async onDeleteConfirm() {
      this.deleteDialog = false;
      await this.deleteProduct(this.selectedItem);
      await this.fetchProducts();
    },
  },
  async created() {
    if (!this.loggedUserPermission.edit_products) {
      this.productHeaders.splice(this.productHeaders.length - 1, 1);
    }
    await this.fetchProducts();
  },
  computed: {
    ...mapGetters("merchant/product", [
      "products",
      "totalProducts",
      "page",
      "limit",
    ]),
    ...mapGetters("merchant/authentication", ["loggedUserPermission"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}

::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.inner-table-td {
  background: $white-color !important;
}
.product-container {
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .price {
    color: #39c25f;
  }
  .description {
    font-size: 12px;
  }
}
</style>
