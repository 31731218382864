<template>
  <div class="orders-container pl-14 pr-14">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Order`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Order? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="totalOrders === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="ordersHeaders"
        :items="orders"
        :expanded.sync="expanded"
        item-key="id"
        :item-class="itemRowBackground"
        hide-default-footer
        @update:page="updatePagination($event)"
        :server-items-length="totalOrders"
        :options="{ page: page, itemsPerPage: limit }"
        @update:items-per-page="updatePerPage($event)"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template>
        <template v-slot:item.tracking_id="props">
          <div class="display-flex align-center">
            <div
              class="expand-icon mr-4 cursor-pointer"
              @click="expandClicked(props, 'Customers', props.item.type)"
            >
              <img
                v-if="!props.item.customerSelected"
                src="@/assets/icons/table-expansion-closed.svg"
                alt="expand-icon"
              />
              <img
                v-else
                src="@/assets/icons/table-expansion-open.svg"
                alt="expand-icon"
              />
            </div>
            <div class="name">
              {{ props.item.tracking_id ? props.item.tracking_id : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.product_category="{ item }">
          <div class="name">
            {{
              item.productdetails ? item.productdetails.product_category : "-"
            }}
          </div>
        </template>

        <template v-slot:item.product_code="{ item }">
          <div class="name">
            {{ item.productdetails ? item.productdetails.product_code : "-" }}
          </div>
        </template>

        <template v-slot:item.order_no="{ item }">
          <div class="name">
            {{ item.orderdetail.orderId ? item.orderdetail.orderId : "-" }}
          </div>
        </template>

        <template v-slot:item.product_name="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.productdetails.product_image"
              :url="`${serverUrl}/${item.productdetails.product_image}`"
              :name="item.productdetails.product_name"
              size="50"
              radius="12"
            />
            <Avatar
              v-else
              :name="item.productdetails.product_name"
              size="50"
              radius="12"
            />
            <div class="ml-2 name">
              {{ item.productdetails ? item.productdetails.product_name : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.description="{ item }">
          <v-tooltip bottom max-width="500px">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="description ellipsis">
                {{
                  item.productdetails
                    ? item.productdetails.product_description
                    : "-"
                }}
              </div>
            </template>
            <div>
              {{
                item.productdetails
                  ? item.productdetails.product_description
                  : "-"
              }}
            </div>
          </v-tooltip>
        </template>

        <template v-slot:item.product_quantity="{ item }">
          <div class="name">
            {{ item.product_quantity ? item.product_quantity : "-" }}
          </div>
        </template>

        <template v-slot:item.created_by="{ item }">
          <div class="name">{{ item.createdby && item.createdby.name ? item.createdby.name : "-" }}</div>
        </template>

        <template v-slot:item.uom="{ item }">
          <div class="name">{{ item.uom ? item.uom : "-" }}</div>
        </template>

        <template v-slot:item.created_at="{ item }">
          <div class="name">
            {{ item.created_at ? formatProductDate(item.created_at) : "-" }}
          </div>
        </template>

        <template v-slot:item.createdby="{ item }">
          <div class="name">
            {{ item.createdby && item.createdby.name ? item.createdby.name : "-" }}
          </div>
        </template>

        <template v-slot:item.expected_delivery_date="{ item }">
          <div class="name">
            {{
              item.expected_delivery_date
                ? formatProductDate(item.expected_delivery_date)
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.price="{ item }">
          <div class="price">
            {{
              item.productdetails.currency
                ? currencyArray[item.productdetails.currency]
                : ""
            }}
            {{
              item.productdetails
                ? formatPrice(
                    item.productdetails.product_price,
                    item.product_quantity
                  )
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.logistics="{ item }">
          <div
            class="logistic-badge exact-center logistic"
            v-if="
              item.logisticdetails &&
                item.status !== 'Parcel ready for shipping'
            "
          >
            {{ item.logisticdetails ? item.logisticdetails.company_name : "" }}
          </div>
          <v-edit-dialog
            :ref="'assignLogistic' + item.id"
            v-else
            :return-value.sync="item.logistics"
            persistent
          >
            <div
              item.status
              v-if="item.status === 'Parcel ready for shipping'"
              class="logistic-badge unassigned exact-center cursor-pointer"
            >
              {{ item.logisticdetails.company_name }}
            </div>
            <div class="display-flex" v-else>
              <div
                class="logistic-badge unassigned exact-center cursor-pointer"
              >
                Unassigned
              </div>
              <img
                v-if="
                  getDateforSameCondition(item) ||
                    getDateforAfterCondition(item)
                "
                class="alert"
                src="@/assets/icons/alert.png"
                alt="alert"
              />
            </div>
            <template v-slot:input>
              <AssignLogisticModal
                @closeClicked="closeAssignModal(item.id)"
                @saveClicked="logisticAssigned(item.id)"
                :item="item"
                :isReassign="item.status === 'Parcel ready for shipping'"
                :key="assignedKey"
              />
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.status="props">
          <div
            class="name button cursor-pointer"
            @click="expandClicked(props, 'Orders', props.item.type)"
            :style="{ color: orderStatus[props.item.status] }"
          >
            {{ props.item.status ? props.item.status : "-" }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="inner-table-td disply-flex" :colspan="headers.length">
            <InnerTable
              :item="item"
              :selectedType="item.type"
              :headers="item.innerHeaders"
              :items="item.innerItems"
              :key="innerTableKey"
            />
          </td>
        </template>

        <template v-slot:item.actions="props">
          <v-tooltip
            max-width="300px"
            v-if="props.item.status && props.item.status !== 'Order placed'"
            bottom
          >
            <template v-slot:activator="{ on }">
              <div
                class="more-options exact-center ml-2 cursor-pointer"
                v-on="on"
              >
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
            </template>
            <div>{{ props.item.status }} - Action blocked!</div>
          </v-tooltip>
          <div class="action-buttons display-flex align-center" v-else>
            <v-edit-dialog :return-value.sync="props.item.action">
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @deleteClicked="deleteClicked(props.item)"
                  @editClicked="editClicked(props.item)"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>
      </v-data-table>
    </div>
    <EditOrder
      :show="showAddOrder"
      :item="selectedItem"
      :width="920"
      :key="addKey"
      @close="showAddOrder = false"
      @saveClicked="saveClicked"
      title="Edit Order"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import {
  ORDER_STATUS,
  SERVER_URL,
  ITEMS_PER_PAGE,
  CURRENCY_SYMBOL,
} from "../../../../constants";
import InnerTable from "@/components/Merchant/Orders/InnerTable";
import AssignLogisticModal from "@/components/Merchant/Orders/AssignLogisticModal";
import EditOrder from "@/components/Merchant/Orders/EditOrder";

export default {
  name: "MerchantOrdersTable",
  components: { InnerTable, AssignLogisticModal, EditOrder },
  data() {
    return {
      itemsPerPage: ITEMS_PER_PAGE,
      serverUrl: SERVER_URL,
      currencyArray: CURRENCY_SYMBOL,
      selectedItem: {},
      isLoading: false,
      isTableLoading: false,
      innerTableKey: 10,
      assignedKey: 30,
      deleteDialog: false,
      showAddOrder: false,
      addKey: 0,
      ordersHeaders: [
        {
          text: "Tracking Id",
          align: "start",
          sortable: false,
          value: "tracking_id",
          width: "150px",
        },
        {
          text: "Order No.",
          align: "start",
          sortable: false,
          value: "order_no",
          width: "150px",
        },
        {
          text: "Logistics",
          align: "start",
          sortable: false,
          value: "logistics",
          width: "190px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
          width: "280px",
        },
        {
          text: "Created by",
          align: "start",
          sortable: false,
          value: "createdby",
          width: "200px",
        },
        {
          text: "Product Category",
          align: "start",
          sortable: false,
          value: "product_category",
          width: "200px",
        },
        {
          text: "Product Code",
          align: "start",
          sortable: false,
          value: "product_code",
          width: "150px",
        },
        {
          text: "Product Name",
          align: "start",
          sortable: false,
          value: "product_name",
          width: "300px",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
          width: "280px",
        },
        {
          text: "Qty",
          align: "start",
          sortable: false,
          value: "product_quantity",
          width: "30px",
        },
        {
          text: "UOM",
          align: "start",
          sortable: false,
          value: "uom",
          width: "150px",
        },
        {
          text: "Created By",
          align: "start",
          sortable: false,
          value: "created_by",
          width: "150px",
        },
        {
          text: "Ordered Date",
          align: "start",
          sortable: false,
          value: "created_at",
          width: "150px",
        },
        {
          text: "Exp del date",
          align: "start",
          sortable: false,
          value: "expected_delivery_date",
          width: "150px",
        },
        {
          text: "Price",
          align: "start",
          sortable: false,
          value: "price",
          width: "200px",
        },

        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      customerHeaders: [
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "customer_name",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "mobile_number",
        },
        {
          text: "Expecting Delivery",
          align: "start",
          sortable: false,
          value: "expected_delivery_date",
        },
        {
          text: "Delivered date",
          align: "center",
          sortable: false,
          value: "delivered_date",
        },
        {
          text: "Proof of Delivery",
          align: "center",
          sortable: false,
          value: "delivered_img",
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "delivery_address",
          width: "250px",
        },
        {
          text: "Billing Address",
          align: "start",
          sortable: false,
          value: "billing_address",
          width: "250px",
        },
        {
          text: "Email Address",
          align: "start",
          sortable: false,
          value: "email",
        },
      ],
      expanded: [],
    };
  },
  computed: {
    ...mapGetters("merchant/orders", [
      "orders",
      "totalOrders",
      "page",
      "limit",
    ]),
    ...mapGetters("merchant/logs", ["logs"]),
  },
  methods: {
    ...mapActions({
      getOrders: "merchant/orders/getOrders",
      setPage: "merchant/orders/setPage",
      setLimit: "merchant/orders/setLimit",
      deleteOrder: "merchant/orders/deleteOrder",
      getOrderLogs: "merchant/logs/getOrderLogs",
    }),
    closeAssignModal(id) {
      this.$refs["assignLogistic" + id].isActive = false;
      this.assignedKey += 1;
    },
    logisticAssigned(id) {
      this.$refs["assignLogistic" + id].isActive = false;
      this.$emit("saveClicked");
      this.assignedKey += 1;
    },
    saveClicked() {
      this.$emit("saveClicked");
    },
    editClicked(item) {
      this.selectedItem = item;
      this.addKey += 1;
      this.showAddOrder = true;
    },
    deleteClicked(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.selectedItem = {};
      this.deleteDialog = false;
    },
    async onDeleteConfirm() {
      this.deleteDialog = false;
      await this.deleteOrder(this.selectedItem);
      await this.fetchOrderData();
    },
    formatDate(date) {
      return moment(date).format("Do MMM,YYYY | h:mm a");
    },
    formatProductDate(date) {
      return moment(date).format("Do MMM,YY");
    },
    formatPrice(price, quantity) {
      const total = price * quantity;
      return total.toFixed(2);
    },
    getInnerItems(item) {
      const itemArr = [];
      item.customer_name =
        item?.customerdetails?.user_first_name +
        " " +
        item?.customerdetails?.user_last_name;
      item.mobile_number = item?.customerdetails?.contact_number;
      itemArr.push(item);
      return itemArr;
    },
    async expandClicked(slotData, type, selectedType) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1 && selectedType === type) {
        this.expanded.splice(indexExpanded, 1);
        this.orders.map((item) => {
          if (item.id === slotData.item.id) {
            item.orderSelected = false;
            item.customerSelected = false;
            item.selected = false;
          }
        });
      } else {
        this.expanded = [];
        this.expanded.push(slotData.item);
        this.orders.map(async (item) => {
          if (item.id === slotData.item.id) {
            switch (type) {
              case "Customers":
                item.orderSelected = false;
                item.customerSelected = true;
                item.selected = true;
                item.innerHeaders = this.customerHeaders;
                item.innerItems = this.getInnerItems(slotData.item);
                item.type = type;
                break;
              case "Orders":
                item.orderSelected = true;
                item.customerSelected = false;
                item.selected = true;
                item.type = type;
                await this.getOrderLogs(item);
                item.logs = this.logs;
                this.innerTableKey += 1;
                break;
            }
          } else {
            item.orderSelected = false;
            item.customerSelected = false;
            item.selected = false;
          }
        });
      }
    },
    async fetchOrderData() {
      try {
        this.isLoading = true;
        await this.getOrders();
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async updatePagination(value) {
      this.expanded = [];
      this.setPage(value);
      await this.fetchOrderData();
    },
    async updatePerPage(value) {
      this.expanded = [];
      this.setLimit(value);
      await this.fetchOrderData();
    },
    getDateforSameCondition(item) {
      let today = moment(new Date()).format("MM-DD-YYYY");
      let expectedDate = moment(item.expected_delivery_date).format(
        "MM-DD-YYYY"
      );
      if (moment(today).isSame(expectedDate)) {
        return true;
      }
      return false;
    },
    getDateforAfterCondition(item) {
      let today = moment(new Date()).format("MM-DD-YYYY");
      let expectedDate = moment(item.expected_delivery_date).format(
        "MM-DD-YYYY"
      );
      if (moment(today).isAfter(expectedDate)) {
        return true;
      }
      return false;
    },
    itemRowBackground(item) {
      let classes = "";
      let today = moment(new Date()).format("MM-DD-YYYY");
      let expectedDate = moment(item.expected_delivery_date).format(
        "MM-DD-YYYY"
      );
      if (
        moment(today).isSame(expectedDate) &&
        item.status !== "Order delivered"
      ) {
        classes = "bg-today";
      }
      if (
        moment(today).isAfter(expectedDate) &&
        item.status !== "Order delivered"
      ) {
        classes = "bg-exceed";
      }
      if (!item.logisticdetails) {
        if (
          moment(today).isSame(expectedDate) ||
          moment(today).isAfter(expectedDate)
        ) {
          classes += " bg-unassigned-exceed";
        } else {
          classes += " bg-unassigned";
        }
      }
      return classes;
    },
  },
  async created() {
    this.orderStatus = ORDER_STATUS;
    await this.fetchOrderData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.alert {
  width: 53px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}
::v-deep .v-small-dialog__content {
  width: auto !important;
}

::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.button {
  border: 1px solid lightgray;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.option-disable {
  pointer-events: none;
  cursor: default;
}

.inner-table-td {
  background: $white-color !important;
}
.orders-container {
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .price {
    color: #39c25f;
    font-weight: 600;
    font-size: 14px;
  }
  .description {
    font-size: 12px;
    font-weight: 600;
    color: $secondary-color;
  }
  .logistic-badge {
    width: 140px;
    padding: 10px;
    border-radius: 6px;
  }
  .unassigned {
    background: #ffeddf;
    font-weight: 600;
    font-size: 12px;
  }
  .logistic {
    background: rgba(212, 5, 17, 0.1);
    font-weight: 600;
    font-size: 12px;
  }
}

::v-deep .bg-today {
  background: #ffeddf !important;
}
::v-deep .bg-exceed {
  background: #ffd7d7 !important;
}
::v-deep .bg-unassigned {
  background: #efffe6 !important;
}
::v-deep .bg-unassigned-exceed {
  background: #fffac9 !important;
}
</style>
