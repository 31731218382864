<template>
  <div class="order-card mb-4 width-100">
    <div
      class="header display-flex width-100 justify-space-between align-center"
    >
      <h1>Create your Order</h1>
      <img
        src="@/assets/icons/dash-orders-img.svg"
        alt="dashboard-order"
        height="72"
      />
    </div>
    <div class="button-container mt-6">
      <div @click="addOrder" class="button cursor-pointer exact-center">Create Order</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MerchantOrdersCard",
  data() {
    return {};
  },
  methods:{
      addOrder(){
          this.$emit("addOrder");
      },
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.order-card {
  height: 220px;
  background: #2c83f3;
  border: 1px solid #0055bb;
  padding: 1.9rem 2rem;
  color: $white-color;
  .header {
    h1 {
      font-weight: 500;
      font-size: 1.125rem;
    }
  }
  .button-container {
    .button {
      width: 10rem;
      padding: 0.6rem 0;
      background: #ff0000;
      box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
      border-radius: 0.25rem;
      font-weight: 600;
      font-size: 1rem;
    }
  }
}
</style>
